import {
    Component,
    OnInit,
    HostListener,
    ViewEncapsulation
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ResidenciasService } from 'src/app/_services/residencias.service';
import { TermosCondicoesComponent } from 'src/app/shared/components/termos-condicoes/termos-condicoes.component';
import {
    SwiperPaginationInterface,
    SwiperConfigInterface,
    SwiperAutoplayInterface
} from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    public iniciarAnimacao: boolean;
    public borderColor = '#EDEDED';
    public consumoKwh: string;
    public ton: string;
    public arvores: string;
    public kmrodado: string;
    public URL_SIMULADOR = '/residencias/simulador';
    public URL_OQUE_E_SOLAR_RESIDENCIAS = '/residencias/o-que-e-solar';
    sizeL = true;
    seloAtivo = false;
    produtos: any;
    seloZIndex: number;
    mobileThreshold = 768;


    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        let posicao = document.documentElement.getBoundingClientRect();
        if (posicao.top < -700 && posicao.top > -1600) {
            this.iniciarAnimacao = true;
        }
    }

    constructor(
        titulo: Title,
          private router: Router,
          private meta: Meta,
          public residenciasService: ResidenciasService) 
          
            {
                this.residenciasService.BuscarBeneficiosResidencias().subscribe(x => {
                this.consumoKwh = x.dado.consumo,
                this.ton = x.dado.tom,
                this.arvores = x.dado.arvores,
                this.kmrodado = x.dado.kmRodado
            });

        titulo.setTitle('EDP Smart - Energia Solar');
        meta.addTag({
            name: 'description',
            content:
                'Com as soluções de Energia Solar EDP você pode gerar e consumir sua própria energia. Aproveite uma fonte limpa e inesgotável disponível para todos.'
        });
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit() {
        scrollTo(0, 0);
     }

    isMobile() {
        return window.matchMedia('screen and (max-width: 768px)').matches;
    }

    private pagination: SwiperPaginationInterface = {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
            return '<span class="' + className + '"></span>';
        }
    };

    private autoPlay: SwiperAutoplayInterface = {
        delay: 105000,
        stopOnLastSlide: false,
        disableOnInteraction: false
    };

    public config: SwiperConfigInterface = {
        a11y: true,
        direction: "horizontal",
        slidesPerView: 1,
        keyboard: false,
        mousewheel: false,
        scrollbar: false,
        navigation: true,
        pagination: this.pagination,
        autoplay: this.autoPlay
    };

    redirecionarTermo(url : string) {
        this.router.navigateByUrl(url);
    }
}
