import { ServiceHelpersService, ContentTypeEnum } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from '../../settings';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ProducaoService {
  constructor(private serviceHelpers: ServiceHelpersService) { }

  public obterProducaoPorDia = (unidadeConsumidoraId: string, data: string): Observable<any> =>
    this.fetchProducaoByUrlSegment(`/api/Producao/ObterProducaoPorDia?unidadeConsumidoraId=${unidadeConsumidoraId}&data=${data}`, null);

  public obterProducaoPorMes = (unidadeConsumidoraId: string, data: string): Observable<any> =>
    this.fetchProducaoByUrlSegment(`/api/Producao/ObterProducaoPorMes?unidadeConsumidoraId=${unidadeConsumidoraId}&data=${data}`, null);

  public obterProducaoPorAno = (unidadeConsumidoraId: string, data: string): Observable<any> =>
    this.fetchProducaoByUrlSegment(`/api/Producao/ObterProducaoPorAno?unidadeConsumidoraId=${unidadeConsumidoraId}&data=${data}`, null);

  public obterProducaoPelosUltimosDozeMeses = (unidadeConsumidoraId: string, dataCorrente: string): Observable<any> =>
    this.fetchProducaoByUrlSegment(`/api/Producao/ObterProducaoPelosUltimosDozeMeses?unidadeConsumidoraId=${unidadeConsumidoraId}&dataCorrente=${dataCorrente}`, null);

  public obterProducaoTotal = (unidadeConsumidoraId: string): Observable<any> =>
    this.fetchProducaoByUrlSegment(`/api/Producao/ObterProducaoTotal?unidadeConsumidoraId=${unidadeConsumidoraId}`, null);

  private fetchProducaoByUrlSegment(url: string, params: HttpParams): Observable<any> {
    return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, url, params);
  }
}
