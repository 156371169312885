import settings from '../../settings';

export function getAuthConfig() {
    return {
        issuer: settings.PROVEDOR_DADOS_SSO,
        clientId: settings.CLIENT_ID,
        postLogoutRedirectUri: window.location.origin + '/signout-callback-oidc',
        redirectUri: window.location.origin + '/loginCallback',
        scope: 'profile edpSolar edpPagamento openid',
        customQueryParams: {
            Origem: 'Ecommerce',
            UnidadeNegocio: 'Solar'
          }
    };
}
