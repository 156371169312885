import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import settings from '../../settings';

const BASE_URL = `${settings.PROVEDOR_DADOS_API}/api/Orcamento`;

@Injectable()
export class OrcamentoB2BService {
    constructor(private serviceHelpers: ServiceHelpersService) { }

    public encaminharOrcamentoB2B(formData): Observable<any> {
        return this.serviceHelpers.postWithoutAuthority<any>(BASE_URL, '/EncaminharFormularioOrcamentoB2B', formData);
    }

    public encaminharOrcamentoB2BAuthority(formData): Observable<any> {
        return this.serviceHelpers.post<any>(BASE_URL, '/EncaminharFormularioOrcamentoB2B', formData);
    }

    public buscarCupom = (codigoCupom: string): Observable<any> =>
        this.fetchOrcamentoB2BByUrlSegment(`/ValidaCupom?codigoCupom=${codigoCupom}`, null)

    public obterCidadeEstadoPorCep = (cep: string, tipoRetorno: string): Observable<any> =>
        this.fetchCepByUrlSegment(`/${cep}/${tipoRetorno}`, null)

    private fetchOrcamentoB2BByUrlSegment(url: string, params: HttpParams): Observable<any> {
        return this.serviceHelpers.getWithoutAuthority<any>(BASE_URL, url, params);
    }

    private fetchCepByUrlSegment(url: string, params: HttpParams): Observable<any> {
        return this.serviceHelpers.getWithoutAuthority<any>('https://viacep.com.br/ws', url, params);
    }

    public validarUnidadeConsumidora(uc: string, siglaEstado: string, documento: string, termoAceite: boolean): Observable<any> {
        return this.serviceHelpers.getWithoutAuthority<any>(settings.PROVEDOR_DADOS_API, `/api/edp/unidade-consumidora/${uc}/documento/${documento}/termo/${termoAceite}/sigla-estado/${siglaEstado}`);
    }
}