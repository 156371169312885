import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-msg-sucesso-orcamento',
    templateUrl: './msg-sucesso-orcamento.component.html',
    styleUrls: ['./msg-sucesso-orcamento.component.scss']
})
export class MsgSucessoOrcamentoComponent implements OnInit {
    ngOnInit(): void {
        window.scrollTo(0, 0);
    }

    @Input() irHomeUrl: string;
    @Input() outrosServicosUrl: string;
    @Input() email: string;

}
