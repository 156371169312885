import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { OAuthService, JwksValidationHandler } from "angular-oauth2-oidc";
import settings from "src/settings";
import { AppHeaderService, NavigationLink } from "ngx-edp-header";
import { LoadingTypeService } from "./_services/loading-type.service";
import { Observable } from "rxjs";
import { IPessoaFisica } from "ngx-edp/lib/types";
import { EdpAuthConfigService } from "ngx-edp";
import { UsuarioService } from "./_services/usuario.service";
import { StringUtil } from "./utils/stringUtil";
import { CheckoutService } from "./_services/checkout.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public redirectSegment: boolean = true;
  public usuarioAtual$: Observable<IPessoaFisica>;
  public readyState: boolean = false;
  public isLoginCallBack: boolean;
  public logoSmartFooter = "../assets/img/logo-small.png";
  public title: "EDP Smart - Energia Solar";
  public logoRedirectUrl = `${settings.URL_SOLAR}/empresas`;
  public checkoutUrl = settings.URL_CHECKOUT;
  public TOKENID: string = "id_token";
  public qtdItensCarrinho: number;
  public navigationLink: NavigationLink[] = [];
  public qtdNotificacao$: Observable<number>;
  public navigationLoaded = false;

  public navigationLinkResidencias: NavigationLink[] = [
    { label: "ENERGIA SOLAR", url: `${settings.URL_SOLAR}/empresas` },
    {
      label: "MOBILIDADE ELÉTRICA",
      url: `${settings.URL_MOBILIDADE}/empresas`,
    },
    { label: "SERVIÇOS", url: `${settings.URL_SERVICOS}/empresas` },
    { label: "SEGUROS", url: `${settings.URL_SEGUROS}/empresas` },
    { label: "FACILITA", url: `${settings.URL_FACILITA}/empresas` },
  ];

  public navigationLinkEmpresas: NavigationLink[] = [
    {
      label: "MOBILIDADE ELÉTRICA",
      url: `${settings.URL_MOBILIDADE}/empresas`,
    },
    {
      label: "MERCADO LIVRE",
      url: `${settings.URL_SMART}/empresas/mercado-livre`,
    },
    { label: "ENERGIA SOLAR", url: `${settings.URL_SOLAR}/empresas` },
  ];

  get urlSolar() {
    return settings.PROVEDOR_DADOS_API;
  }
  public settings = settings;

  showSeach = false;
  showLogin = true;
  showBasket = false;

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private appHeaderService: AppHeaderService,
    private loadingTypeService: LoadingTypeService,
    private edpAuthConfigService: EdpAuthConfigService,
    private usuarioService: UsuarioService,
    private checkoutService: CheckoutService
  ) {
    this.logoRedirectUrl = `${settings.URL_SMART}/empresas`;
    if ((window as any).ga && (window as any).ga.getAll) {
      this.router.events.subscribe((event) => {
        const ga = (window as any).ga;
        if (event instanceof NavigationEnd) {
          ga("set", "page", event.urlAfterRedirects);
          ga("send", "pageview");
        }
      });
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.navigationLoaded = true;
      }
    });
  }

  ngOnInit() {
    const target = "empresas";
    this.setNavigationLink(target);
    this.configurarLoginImplicito();
    this.verificaRota();
    this.subscribers();
    this.configurarTagManager();
    this.configurarLoading();
    this.cleanConsole();
  }

  configurarTagManager() {
    const scriptGTM = document.createElement("script");
    scriptGTM.append(`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${settings.GOOGLE_TAG_MANAGER_KEY}' );`);
    document.head.prepend(scriptGTM);

    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src =
      "https://www.googletagmanager.com/ns.html?id=" +
      settings.GOOGLE_TAG_MANAGER_KEY;
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    iframe.height = "0";
    iframe.width = "0";
    noscript.appendChild(iframe);
    document.body.prepend(noscript);
  }

  verificaRota() {
    if (window.location.href.includes("loginCallback")) {
      this.isLoginCallBack = true;
    } else if (window.location.href.split("/")[3] === "") {
      this.router.navigateByUrl("/empresas");
    } else {
      this.isLoginCallBack = false;
    }
  }

  configurarLoading() {
    this.loadingTypeService.changeReadyState().subscribe((result) => {
      this.readyState = result;
    });
  }

  private obterUsuarioAtual() {
    if (!StringUtil.isNullOrUndefined(localStorage.getItem(this.TOKENID))) {
      this.usuarioService.obterDadosUsuario().subscribe((response) => {
        this.usuarioService.usuarioAtual.next(response.dado);
      });

      this.qtdNotificacao$ = this.usuarioService
        .contarNotificacoesUsuario()
        .map((x) => x.dado);
    }
  }

  private subscribers() {
    this.appHeaderService.eventoLogin().subscribe(() => {
      if (!StringUtil.isNullOrUndefined(localStorage.getItem(this.TOKENID))) {
        window.location.href = settings.URL_MINHA_CONTA;
      } else {
        this.oauthService.initImplicitFlow("login");
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.checkoutService.obterQuantidadeCarrinho().subscribe((response) => {
      this.qtdItensCarrinho = response.dado;
    });

    this.edpAuthConfigService.onLogin.subscribe((x) => {
      this.obterUsuarioAtual();
      this.usuarioAtual$ = this.usuarioService.usuarioAtual;
    });
    this.edpAuthConfigService.onLogout.subscribe((x) => {
      this.usuarioService.usuarioAtual.next({} as IPessoaFisica);
    });
  }

  private configurarLoginImplicito() {
    if (StringUtil.isNullOrUndefined(localStorage.getItem(this.TOKENID))) {
      document.addEventListener("readystatechange", (event) => {
        var target = event.target as any;
        if (target.readyState === "complete") {
          this.obterUsuarioAtual();
          this.usuarioAtual$ = this.usuarioService.usuarioAtual;
        }
      });
    }
  }

  private cleanConsole() {
    var url = window.location.host;
    if (url.indexOf("localhost") || url.indexOf("dev") > 0) {
      var w = window as any;
      w.log = [];
      console.error = function () {
        w.log.push(arguments);
      };
    }
  }

  private setNavigationLink(target: string) {
    this.navigationLink =
      target === "empresas"
        ? this.navigationLinkEmpresas
        : this.navigationLinkResidencias;
  }
}
