/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-edp/ngx-edp.ngfactory";
import * as i3 from "ngx-edp";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "./faq.component";
var styles_EdpFaqSolarComponent = [i0.styles];
var RenderType_EdpFaqSolarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EdpFaqSolarComponent, data: {} });
export { RenderType_EdpFaqSolarComponent as RenderType_EdpFaqSolarComponent };
export function View_EdpFaqSolarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-faq", [["class", "fix-margin"]], null, null, null, i2.View_ɵcr_0, i2.RenderType_ɵcr)), i1.ɵdid(1, 114688, null, 0, i3.ɵcr, [i4.Title, i3.EdpFaqService, i5.Router, i3.ɵck], { breadCrumb: [0, "breadCrumb"], icon: [1, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadCrumb; var currVal_1 = "Bop-007"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EdpFaqSolarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-faq-solar", [], null, null, null, View_EdpFaqSolarComponent_0, RenderType_EdpFaqSolarComponent)), i1.ɵdid(1, 114688, null, 0, i6.EdpFaqSolarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EdpFaqSolarComponentNgFactory = i1.ɵccf("edp-faq-solar", i6.EdpFaqSolarComponent, View_EdpFaqSolarComponent_Host_0, { breadCrumb: "breadCrumb" }, {}, []);
export { EdpFaqSolarComponentNgFactory as EdpFaqSolarComponentNgFactory };
