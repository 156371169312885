<app-header-grandes-empresas></app-header-grandes-empresas>

<div class="card-empresas-desk-container">
  <div class="container-fluid">
    <div class="row">

      <div class="card-pequenas-empresas-container col-md-6">
        <div class="card-pequenas-empresas cardEmpresas">
          <div class="col-md-6 alinhamento-pequenas-empresas">
            <div class="row">
              <div class="col-sm-auto">
                <div class="icone-1">
                  <img class="economia-mensal-icone" src="../../../../assets/icons/empresas/Bra-032.png">
                </div>
                <br>
                <div class="espacamento-titulo">
                  <p class="text-uppercase solar-empresas-titulo">CONSUMO ATÉ</p>
                  <p class="text-uppercase solar-empresas-titulo">R$ 30.000/MÊS</p>
                </div>
                <p class="solar-empresas-sub-titulo">
                  Se suas contas de energia têm valores </p>
                  <p class="solar-empresas-sub-titulo">
                    para pagamento iguais ou inferiores </p>
                  <p class="solar-empresas-sub-titulo">
                    a R$ 30.000,00/mês</p>
                    <div class="espacamento-titulo">
                      <div class="espacamento-titulo">
                        <div class="rodape-unidades">
                          <a href="/empresas/pequenas-empresas/simulador">
                            <edp-button>Fazer Simulação</edp-button>
                          </a>
                        </div>
                      </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-grandes-empresas-container col-md-6">
        <div class="card-grandes-empresas cardEmpresas">
          <div class="col-md-6 alinhamento-grandes-empresas">
            <div class="row">
              <div class="col-sm-auto">
                <div class="icone-2">
                  <img class="economia-mensal-icone" src="../../../../assets/icons/empresas/Bra-019.png">
                </div>
                <br>
                <div class="espacamento-titulo">
                  <p class="text-uppercase solar-empresas-titulo">
                    CONSUMO A PARTIR DE
                  </p>
                  <p class="text-uppercase solar-empresas-titulo">R$ 30.000/MÊS</p>
                </div>
                <p class="solar-empresas-sub-titulo">
                  Se suas contas de energia têm valores </p>
                  <p class="solar-empresas-sub-titulo">para pagamento superiores</p>
                  <p class="solar-empresas-sub-titulo">a R$ 30.000,00/mês</p>
                <div class="espacamento-titulo pb-4">
                  <div class="rodape-unidades">
                      <a (click)="oContato()">
                          <edp-button name="contato">Solicitar Contato</edp-button>
                      </a>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>