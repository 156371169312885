import { Component, OnInit, Input } from "@angular/core";
import settings from "../../../../settings";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  ano: number;
  @Input() logoApp = "/assets/img/logo-small.png";
  @Input() UrlSobre = "https://brasil.edp.com/pt-br";
  @Input() UrlGrupoEdp = "https://www.edp.com/";
  @Input() UrlTrabalheConosco = "https://brasil.edp.com/pt-br/carreiras";
  public settings = settings;
  public urlContato: string;
  public urlPoliticaCookie: string;
  public urlPoliticaPrivacidade: string;
  public urlSeguranca: string;
  public urlFaq: string;
  constructor() {}

  ngOnInit() {
    this.urlContato = `${settings.URL_SMART}/empresas/contato`;
    this.urlPoliticaCookie = `${settings.URL_SMART}/politica-de-cookies`;
    this.urlPoliticaPrivacidade = `${settings.URL_SMART}/empresas/politica-de-privacidade`;
    this.urlSeguranca = `${settings.URL_SMART}/empresas/protecao-de-dados-pessoais-e-seguranca-da-informacao`;
    this.urlFaq = `${settings.URL_SMART}/empresas/faq`;
    this.ano = new Date().getFullYear();
  }
}
