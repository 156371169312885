import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-faq-artigo-solar',
    templateUrl: './faq-artigo.component.html',
    styleUrls: ['./faq-artigo.component.scss']
})
export class FaqArtigoComponent implements OnInit {

    public usuarioAtual$: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(private usuarioService: UsuarioService, private oauthService: OAuthService) { }

    ngOnInit() {
        this.usuarioAtual$.next({});

        if (this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) {
            this.usuarioService.obterDadosUsuario().subscribe(x => {
                this.usuarioAtual$.next({ guid: x.dado.id, nome: x.dado.nome, email: x.dado.email });
            });
        }
    }
}
