
import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from '../../settings';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { IProvedorResponse } from 'ngx-edp';

@Injectable()
export class CheckoutService {
    constructor(
        private serviceHelpers: ServiceHelpersService,
        private cookieService: CookieService) { }

    public obterQuantidadeCarrinho(): Observable<IProvedorResponse<number>> {
        const usuarioGuid = this.cookieService.get('usuarioGuid');
        return this.serviceHelpers.getWithoutAuthority<any>(settings.PROVEDOR_DADOS_API,
            `api/checkout/carrinho/carrinho-ativo/${(usuarioGuid === '' ? '00000000-0000-0000-0000-000000000000' : usuarioGuid)}/quantidade`);
    }
}



