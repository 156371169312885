import { GrandesEmpresasComponent } from "./core/grandes-empresas/grandes-empresas.component";
import { Routes, UrlSegment, UrlMatchResult, } from "@angular/router";
import { LoginComponent } from "./core/login/login.component";
import { LoginCallbackComponent } from "./core/login/login-callback.component";
import { GrandesEmpresasContatoComponent } from "./core/grandes-empresas/grandes-empresas-contato/grandes-empresas-contato.component";
import { HomeComponent } from "./core/home/residencias/home.component";
import { TermosECondicoesB2cComponent } from "./core/termos-e-condicoes/termos-e-condicoes-b2c/termos-e-condicoes-b2c.component";
import { TermosECondicoesB2bComponent } from "./core/termos-e-condicoes/termos-e-condicoes-b2b/termos-e-condicoes-b2b.component";
import { PoliticaDePrivacidadeComponent } from "./core/politica-de-privacidade/politica-de-privacidade.component";
import { MsgSucessoOrcamentoComponent } from "./shared/components/msg-sucesso-orcamento/msg-sucesso-orcamento.component";
import { AuthGuard } from "./_guards/auth-guard";
import { AuthComponent } from "./core/auth/auth.component";
import { EmpresasComponent } from "./core/home/empresas/empresas.component";
import { EdpFaqSolarComponent } from "./core/faq/faq/faq.component";
import { FaqArtigoComponent } from "./core/faq/faq-artigo/faq-artigo.component";
import { FaqResultadoComponent } from "./core/faq/faq-resultado/faq-resultado.component";
import { FaqTopicoSolarComponent } from "./core/faq/faq-topico/faq-topico.component";
const ɵ0 = {
    title: "EDP Smart - Energia Solar",
};
const home = {
    path: "residencias",
    component: HomeComponent,
    data: ɵ0,
};
const ɵ1 = {
    title: "EDP Smart - Energia Solar",
};
const homeEmpresas = {
    path: "empresas",
    component: EmpresasComponent,
    data: ɵ1,
};
const ɵ2 = {
    title: "Login",
};
const loginRoute = {
    path: "login",
    component: LoginComponent,
    data: ɵ2,
};
const ɵ3 = {
    title: "Login CallBack",
};
const loginCallbackRoute = {
    path: "loginCallback",
    component: LoginCallbackComponent,
    data: ɵ3,
};
const ɵ4 = {
    title: "Login",
};
const AuthRoute = {
    matcher: UrlAuth,
    component: AuthComponent,
    canActivate: [AuthGuard],
    data: ɵ4,
};
const ɵ5 = {
    title: "Pequenas ou Grandes Empresas",
};
const grandesEmpresasRoute = {
    path: "empresas/pequenas-ou-grandes-empresas",
    component: GrandesEmpresasComponent,
    data: ɵ5,
};
const ɵ6 = {
    title: "Grandes Empresas",
};
const grandesEmpresasContatoRoute = {
    path: "empresas/grandes-empresas/contato",
    component: GrandesEmpresasContatoComponent,
    data: ɵ6,
};
const ɵ7 = {
    title: "EDP Smart - Energia Solar - Agradecimento",
};
const agradecimentoComponent = {
    path: "empresas/pequenas-empresas/agradecimento",
    component: MsgSucessoOrcamentoComponent,
    data: ɵ7,
};
const ɵ8 = {
    title: "EDP Smart - Energia Solar - Nossos Termos e Condições",
};
const termosECondicoesB2CRoute = {
    path: "residencias/termos-e-condicoes",
    component: TermosECondicoesB2cComponent,
    data: ɵ8,
};
const ɵ9 = {
    title: "EDP Smart - Energia Solar - Nossos Termos e Condições para Empresas",
};
const termosECondicoesB2BRoute = {
    path: "empresas/termos-e-condicoes",
    component: TermosECondicoesB2bComponent,
    data: ɵ9,
};
const ɵ10 = {
    title: "EDP Smart - Energia Solar - Politica de Privacidade",
};
const politicaDePrivacidadeB2BRoute = {
    path: "politica-de-privacidade",
    component: PoliticaDePrivacidadeComponent,
    data: ɵ10,
};
const ɵ11 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqB2BRoute = {
    path: "empresas/faq",
    component: EdpFaqSolarComponent,
    data: ɵ11,
};
const ɵ12 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqB2CRoute = {
    path: "residencias/faq",
    component: EdpFaqSolarComponent,
    data: ɵ12,
};
const ɵ13 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqTopicoB2BRoute = {
    path: "empresas/faq/:topico/:topicoId",
    component: FaqTopicoSolarComponent,
    data: ɵ13,
};
const ɵ14 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqTopicoB2CRoute = {
    path: "residencias/faq/:topico/:topicoId",
    component: FaqTopicoSolarComponent,
    data: ɵ14,
};
const ɵ15 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqArtigoB2BRoute = {
    path: "empresas/faq/:topicoTitulo/:artigoTitulo/:artigoId",
    component: FaqArtigoComponent,
    data: ɵ15,
};
const ɵ16 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqArtigoB2CRoute = {
    path: "residencias/faq/:topicoTitulo/:artigoTitulo/:artigoId",
    component: FaqArtigoComponent,
    data: ɵ16,
};
const ɵ17 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqResultadoB2CRoute = {
    path: "residencias/faq/resultado/:search",
    component: FaqResultadoComponent,
    data: ɵ17,
};
const ɵ18 = {
    title: "EDP Smart - Energia Solar - FAQ",
};
const faqResultadoB2BRoute = {
    path: "empresas/faq/resultado/:search",
    component: FaqResultadoComponent,
    data: ɵ18,
};
const ɵ19 = () => import("./modules/components/simulador/simulador-b2c/simulador-b2c.module.ngfactory").then((m) => m.SimuladorB2CModuleNgFactory), ɵ20 = () => import("./modules/components/simulador/simulador-b2b/simulador-b2b.module.ngfactory").then((m) => m.SimuladorB2BModuleNgFactory);
const SimuladorRoutes = [
    {
        path: "residencias/simulador",
        loadChildren: ɵ19,
    },
    {
        path: "empresas/pequenas-empresas/simulador",
        loadChildren: ɵ20,
    },
];
const ɵ21 = () => import("./modules/components/orcamento/orcamento-b2c/orcamento-b2c.module.ngfactory").then((m) => m.OrcamentoB2CModuleNgFactory), ɵ22 = () => import("./modules/components/orcamento/orcamento-b2b/orcamento-b2b.module.ngfactory").then((m) => m.OrcamentoB2BModuleNgFactory);
const OrcamentoRoutes = [
    {
        path: "residencias/orcamento",
        loadChildren: ɵ21,
    },
    {
        path: "empresas/pequenas-empresas/orcamento",
        loadChildren: ɵ22,
    },
];
const ɵ23 = () => import("./modules/components/oque-e-solar/oque-e-solar-empresas/oque-e-solar-empresas.module.ngfactory").then((m) => m.OQueESolarEmpresasModuleNgFactory), ɵ24 = () => import("./modules/components/oque-e-solar/oque-e-solar/oque-e-solar.module.ngfactory").then((m) => m.OqueESolarModuleNgFactory), ɵ25 = () => import("./modules/components/oque-e-solar/oque-e-solar-residencias/oque-e-solar-residencias.module.ngfactory").then((m) => m.OqueESolarResidenciasModuleNgFactory);
const OQueERoutes = [
    {
        path: "empresas/o-que-e-solar-para-empresas",
        loadChildren: ɵ23,
    },
    {
        path: "residencias/o-que-e-solar",
        loadChildren: ɵ24,
    },
    {
        path: "residencias/o-que-e-solar-para-residencias",
        loadChildren: ɵ25,
    },
];
const redirectNull = {
    path: "null",
    redirectTo: "",
};
export function UrlAuth(url) {
    if (url.length === 0) {
        return null;
    }
    const param = url[url.length - 1].toString();
    if (param === "auth") {
        return { consumed: url };
    }
    return null;
}
const routes = [
    home,
    homeEmpresas,
    loginRoute,
    loginCallbackRoute,
    faqResultadoB2CRoute,
    faqResultadoB2BRoute,
    AuthRoute,
    grandesEmpresasRoute,
    grandesEmpresasContatoRoute,
    termosECondicoesB2CRoute,
    termosECondicoesB2BRoute,
    politicaDePrivacidadeB2BRoute,
    agradecimentoComponent,
    faqB2BRoute,
    faqB2CRoute,
    faqTopicoB2BRoute,
    faqTopicoB2CRoute,
    faqArtigoB2BRoute,
    faqArtigoB2CRoute,
    ...SimuladorRoutes,
    ...OQueERoutes,
    ...OrcamentoRoutes,
    redirectNull,
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25 };
