import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import settings from "src/settings";

export enum CodigoAreaNegocio {
  Eficiencia = 448780005,
  Energia = 448780006,
  Mobilidade = 448780003,
  Solar = 448780004,
}

export enum CodigoAResponsabilidadeComercial {
  S2C = 448780000,
  RepresentanteComercial = 448780001,
  ComercialInterno = 448780002,
  SiteB2B = 448780004, //here
}
export enum Status {
  Novo = 1,
  EmEnriquecimento = 2,
  Enriquecido = 448780002,
}
export interface IClientePotencial {
  Nome_Empresa: string;
  Razao_Social: string;
  Cnpj: string;
  Nome_Contato: string;
  Sobrenome_Contato: string;
  Telefone: string;
  Email: string;
  Celular: string;
  Linkedin_Contato: string;
  Linkedin_Conta: string;
  Codigo_origem: number;
  Observacoes: string;
  Solucoes_Interesse: string;
  Codigo_area_negocio: CodigoAreaNegocio;
  Aceita_receber_ofertas_edp: boolean;
  Status: Status;
  Gasto_anual_total_energia: number;
  Unidade_medida_consumo_total_anual: number;
  Consumo_total_anual: number;
  Cep: string;
  Classe_tarifaria_codigo: number;
  Codigo_ibge_cidade: number;
  Codigo_ibge_uf_estado: string;
}
export interface IClientePotencialResponse {
  IsSuccessful: boolean;
  Message: string;
  Dados: any;
  Codigo: number;
}

@Injectable({ providedIn: "root" })
export class ClientePotencialService {
  public token: any = "";
  public returnAPI: any = "";

  constructor(private http: HttpClient) {}

  gerarLead(dadosCliente: IClientePotencial): Observable<string> {
    const url = `${settings.URL_CLIENTE_POTENCIAL}/v3/clientepotencial`;

    return this.getToken().pipe(
      mergeMap((data) => {
        this.token = data;
        const headers = new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
          "Ocp-Apim-Subscription-Key": settings.APIM_KEY_CLIENTE_POTENCIAL,
        });
        return this.http
          .post(url, JSON.stringify(dadosCliente), {
            headers: headers,
          })
          .pipe(map((x) => (x ? x.toString() : null)));
      })
    );
  }

  getSetores(): Observable<any> {
    const url = `${settings.URL_CLIENTE_POTENCIAL}/v1/setores`;

    return this.getToken().pipe(
      mergeMap((data) => {
        this.token = data;
        const headers = new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
          "Ocp-Apim-Subscription-Key": settings.APIM_KEY_CLIENTE_POTENCIAL,
        });
        return this.http
          .get(url, { headers: headers })
          .pipe(map((x) => x));
      })
    );
  }

  getToken(): Observable<string> {
    const header = new HttpHeaders({
      "user-id": settings.USER_ID_CLIENTE_POTENCIAL,
      "Ocp-Apim-Subscription-Key": settings.APIM_KEY_CLIENTE_POTENCIAL,
    });
    const requestOptions = { headers: header };
    const url = `${settings.URL_CLIENTE_POTENCIAL}/token`;

    return this.http.get(url, requestOptions).pipe(map((x) => x.toString()));
  }
}
