import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule, registerLocaleData } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatRippleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { NgSelectModule } from "@ng-select/ng-select";
import "hammerjs";
import pt from "@angular/common/locales/pt";

import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxMaskModule } from "ngx-mask";
import { SharedModule } from "../shared/shared.module";
import { GrandesEmpresasService } from "../_services/grandes-empresas.service";
import { OrcamentoB2BService } from "../_services/orcamento-b2b.service";
import { OrcamentoB2CService } from "../_services/orcamento-b2c.service";
import { PotenciaService } from "../_services/potencia.service";
import { ProducaoService } from "../_services/producao.service";
import { SimuladorService } from "../_services/simulador.service";
import { UsinasService } from "../_services/usinas.service";
import { BaseUsinaComponent } from "./base-usina/base-usina.component";
import { CardsUsinasComponent } from "./base-usina/usinas/cards-usinas/cards-usinas.component";
import { BalancoEnergeticoAnoComponent } from "./base-usina/usinas/detalhe-usina/balanco-energetico/balanco-energetico-ano/balanco-energetico-ano.component";
import { BalancoEnergeticoDiaComponent } from "./base-usina/usinas/detalhe-usina/balanco-energetico/balanco-energetico-dia/balanco-energetico-dia.component";
import { BalancoEnergeticoDozeMesesComponent } from "./base-usina/usinas/detalhe-usina/balanco-energetico/balanco-energetico-doze-meses/balanco-energetico-doze-meses.component";
import { BalancoEnergeticoMesComponent } from "./base-usina/usinas/detalhe-usina/balanco-energetico/balanco-energetico-mes/balanco-energetico-mes.component";
import { BalancoEnergeticoTotalComponent } from "./base-usina/usinas/detalhe-usina/balanco-energetico/balanco-energetico-total/balanco-energetico-total.component";
import { BalancoEnergeticoComponent } from "./base-usina/usinas/detalhe-usina/balanco-energetico/balanco-energetico.component";
import { DetalheUsinaComponent } from "./base-usina/usinas/detalhe-usina/detalhe-usina.component";
import { PotenciaAtualComponent } from "./base-usina/usinas/detalhe-usina/potencia-atual/potencia-atual.component";
import { ListaUsinasComponent } from "./base-usina/usinas/lista-usinas/lista-usinas.component";
import { UsinasComponent } from "./base-usina/usinas/usinas.component";
import { WorkflowUsinaComponent } from "./base-usina/workflow-usina/workflow-usina.component";
import { GrandesEmpresasContatoComponent } from "./grandes-empresas/grandes-empresas-contato/grandes-empresas-contato.component";
import { GrandesEmpresasComponent } from "./grandes-empresas/grandes-empresas.component";
import { HeaderGrandesEmpresasComponent } from "./grandes-empresas/header-grandes-empresas/header-grandes-empresas.component";
import { MensagemEnviadaComponent } from "./grandes-empresas/mensagem-enviada/mensagem-enviada.component";
import { HomeComponent } from "./home/residencias/home.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TermosECondicoesB2cComponent } from "./termos-e-condicoes/termos-e-condicoes-b2c/termos-e-condicoes-b2c.component";
import { TermosECondicoesB2bComponent } from "./termos-e-condicoes/termos-e-condicoes-b2b/termos-e-condicoes-b2b.component";
import { PoliticaDePrivacidadeServico } from "../_services/politica-de-privacidade.service";
import { PoliticaDePrivacidadeComponent } from "./politica-de-privacidade/politica-de-privacidade.component";
import { TermosECondicoesServico } from "../_services/termos-e-condicoes.service";
import { SwiperModule } from "ngx-swiper-wrapper";
import { NgxEdpSeloDoProdutoModule } from "ngx-edp-selo-do-produto";
import { AuthComponent } from "./auth/auth.component";
import { EmpresasComponent } from "./home/empresas/empresas.component";
import {
  EdpHomeModule,
  EdpModule,
  EdpFaqModule,
  EdpIconsModule,
  EdpFormsModule,
} from "ngx-edp";
import { FaqArtigoComponent } from "./faq/faq-artigo/faq-artigo.component";
import { EdpPaginatorModule, EdpSwiperModule } from "ngx-edp";
import { FaqResultadoComponent } from "./faq/faq-resultado/faq-resultado.component";
import { EdpFaqSolarComponent } from "./faq/faq/faq.component";
import { FaqTopicoSolarComponent } from "./faq/faq-topico/faq-topico.component";
import { getFaqConfig } from "../_config/faqConfig";
import { RouterModule } from "@angular/router";

registerLocaleData(pt);

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    CdkTableModule,
    FormsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgSelectModule,
    CurrencyMaskModule,
    NgOptionHighlightModule,
    MatButtonModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    RecaptchaFormsModule,
    RecaptchaModule.forRoot(),
    NgbModule,
    SwiperModule,
    NgxEdpSeloDoProdutoModule,
    EdpHomeModule,
    EdpIconsModule,
    EdpFaqModule.config(getFaqConfig),
    EdpModule,
    EdpPaginatorModule,
    EdpFormsModule,
    RouterModule,
    EdpSwiperModule,
  ],
  declarations: [
    BaseUsinaComponent,
    UsinasComponent,
    WorkflowUsinaComponent,
    ListaUsinasComponent,
    CardsUsinasComponent,
    DetalheUsinaComponent,
    PotenciaAtualComponent,
    BalancoEnergeticoComponent,
    BalancoEnergeticoDiaComponent,
    BalancoEnergeticoMesComponent,
    BalancoEnergeticoAnoComponent,
    BalancoEnergeticoDozeMesesComponent,
    BalancoEnergeticoTotalComponent,
    GrandesEmpresasComponent,
    GrandesEmpresasContatoComponent,
    HeaderGrandesEmpresasComponent,
    MensagemEnviadaComponent,
    HomeComponent,
    TermosECondicoesB2cComponent,
    TermosECondicoesB2bComponent,
    PoliticaDePrivacidadeComponent,
    AuthComponent,
    EmpresasComponent,
    FaqArtigoComponent,
    FaqResultadoComponent,
    EdpFaqSolarComponent,
    FaqTopicoSolarComponent,
  ],
  exports: [MatSortModule, MatRippleModule],
  providers: [
    UsinasService,
    PotenciaService,
    ProducaoService,
    OrcamentoB2CService,
    OrcamentoB2BService,
    SimuladorService,
    GrandesEmpresasService,
    TermosECondicoesServico,
    PoliticaDePrivacidadeServico,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
  ],
})
export class CoreModule {}
