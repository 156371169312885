import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from '../../settings';
import { Observable } from 'rxjs';

@Injectable()
export class UsinasService {
    constructor(private serviceHelpers: ServiceHelpersService) { }

    public obterUsinasUsuario(): Observable<any> {
        return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, 'api/Usinas/ObterPorUsuario', null, true);
    }
}
