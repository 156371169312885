import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/auth-guard';
import { JwtService } from './_services/jwt.service';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorClass, ServiceHelpersService } from './_services/service-helper.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './core/login/login.component';
import { LoginCallbackComponent } from './core/login/login-callback.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { getPaginatorIntl } from './_material/custom-mat-paginator-intl';
import { MatPaginatorIntl } from '@angular/material';;
import { NgxEdpHeaderModule } from 'ngx-edp-header';
import { EdpLoadingModule, EdpHomeModule, EdpFaqModule, EdpAuthModule, EdpConfigModule, EdpFormsModule } from 'ngx-edp';
import { NgxEdpCookiesConsentModule } from 'ngx-edp-cookies-consent';
import { getFaqConfig } from './_config/faqConfig';
import { getAuthConfig } from './_config/authConfig';
import { getConfig } from './_config/config';
import { CookieService } from 'ngx-cookie-service';
import { ResidenciasService } from './_services/residencias.service';
import { UsuarioService } from './_services/usuario.service';
import { CheckoutService } from './_services/checkout.service';
import { GrandesEmpresasService } from './_services/grandes-empresas.service';
import { NgxMaskModule } from 'ngx-mask';
import { TermosECondicoesServico } from './_services/termos-e-condicoes.service';
import { PoliticaDePrivacidadeServico } from './_services/politica-de-privacidade.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginCallbackComponent,
    LoginComponent
  ],
  exports: [
    CdkTableModule
  ],
  imports: [
    SharedModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    BrowserAnimationsModule,
    NgxEdpHeaderModule,
    EdpLoadingModule,
    EdpHomeModule,
    NgxMaskModule.forRoot(),
    EdpFaqModule.config(getFaqConfig),
    EdpAuthModule.config(getAuthConfig),
    EdpConfigModule.config(getConfig),
    CoreModule,
    NgxEdpCookiesConsentModule
  ],
  providers: [
    AuthGuard,
    JwtService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClass,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useValue: getPaginatorIntl()
    },
    ResidenciasService,
    ServiceHelpersService,
    CookieService,
    CheckoutService,
    UsuarioService,
    GrandesEmpresasService,
    TermosECondicoesServico,
    PoliticaDePrivacidadeServico
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
