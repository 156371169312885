import { Injectable } from "@angular/core";
import { ServiceHelpersService } from "./service-helper.service";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import settings from "src/settings";
import { InformacoesSimulacaoB2C } from "../modules/components/simulador/simulador-b2c/model/informacoes-simulacao-b2c-model";
import { InformacoesSimulacao } from "../modules/components/simulador/simulador-b2b/model/informacoes-simulacao-model";

const BASE_URL = `${settings.PROVEDOR_DADOS_API}/api/Simulador`;

@Injectable()
export class SimuladorService {
  constructor(private serviceHelpers: ServiceHelpersService) {}

  public obterCidadeEstadoPorCep = (
    cep: string,
    tipoRetorno: string
  ): Observable<any> =>
    this.fetchCepByUrlSegment(`/${cep}/${tipoRetorno}`, null);

  public obterTodosOsEstadosDisponiveis = (): Observable<any> =>
    this.fetchSimuladorByUrlSegment(`/ObterEstadosDisponiveis`, null);

  public obterTodasCidadesDisponiveisPorEstado = (
    uf: string
  ): Observable<any> =>
    this.fetchSimuladorByUrlSegment(`/ObterCidadesDisponiveis?uf=${uf}`, null);

  public obterResultadoSimulacaoB2C(
    clienteSimulacaoB2CModel: InformacoesSimulacaoB2C
  ) {
    return this.serviceHelpers.postWithoutAuthority<any>(
      BASE_URL,
      "/ObterSimulacao",
      clienteSimulacaoB2CModel
    );
  }

  public obterResultadoSimulacaoB2CAutority(
    clienteSimulacaoB2CModel: InformacoesSimulacaoB2C
  ) {
    return this.serviceHelpers.post<any>(
      BASE_URL,
      "/ObterSimulacao",
      clienteSimulacaoB2CModel
    );
  }

  public obterResultadoSimulacaoB2B(
    clienteSimulacaoB2BModel: InformacoesSimulacao
  ) {
    // return this.fakeData();
    return this.serviceHelpers.postWithoutAuthority<any>(
      BASE_URL,
      "/ObterSimulacaoB2B",
      clienteSimulacaoB2BModel
    );
  }

  public obterResultadoSimulacaoB2BAuthority(
    clienteSimulacaoB2BModel: InformacoesSimulacao
  ) {
    return this.serviceHelpers.post<any>(
      BASE_URL,
      "/ObterSimulacaoB2B",
      clienteSimulacaoB2BModel
    );
  }

  public obterSimulacaoB2CPorId(simulacaoId: string) {
    return this.serviceHelpers.get<any>(
      BASE_URL,
      `/ConsultarSimulacaoB2CPorId?simulacaoId=${simulacaoId}`,
      null,
      true
    );
  }

  public obterSimulacaoB2CPorIdSemOrcamento(simulacaoId: string) {
    return this.serviceHelpers.get<any>(
      BASE_URL,
      `/ConsultarSimulacaoB2CPorIdSemOrcamento?simulacaoId=${simulacaoId}`,
      null,
      true
    );
  }

  public obterSimulacaoB2BPorIdSemOrcamento(simulacaoId: string) {
    return this.serviceHelpers.get<any>(
      BASE_URL,
      `/ConsultarSimulacaoB2BPorIdSemOrcamento?simulacaoId=${simulacaoId}`,
      null,
      true
    );
  }

  public obterSimulacaoB2BPorId(simulacaoId: string) {
    return this.serviceHelpers.get<any>(
      BASE_URL,
      `/ConsultarSimulacaoB2BPorId?simulacaoId=${simulacaoId}`,
      null,
      true
    );
  }

  private fetchSimuladorByUrlSegment(
    url: string,
    params: HttpParams
  ): Observable<any> {
    return this.serviceHelpers.getWithoutAuthority<any>(BASE_URL, url, params);
  }

  private fetchCepByUrlSegment(
    url: string,
    params: HttpParams
  ): Observable<any> {
    return this.serviceHelpers.getWithoutAuthority<any>(
      "https://viacep.com.br/ws",
      url,
      params
    );
  }

  private fakeData() {
    const response = {
      sucesso: false,
      codigoStatus: 100,
      usinaStatusEnum: 1,
      dado: {
        token: "f493b033-9d18-4fd7-baf8-1899fad147dc",
        simulacao: {
          economiaMensalEstimada: 500,
          economiaContaAtual: 190,
          estimativaCusto: 505,
          payback: 200,
          tir: 154,
          potenciaComercialSistema: 80,
          producaoEstimadaMes: 548,
        },
        distribuidora: "EDP SP",
      },
      mensagem:
        "A requisição não pode ser concluída, verifique os erros e tente novamente.",
      erros: ["Obrigado! Em breve entraremos em contato."],
    };

    const observable = new Observable((subscriber) => {
      setTimeout(() => {
        subscriber.next(response);
      }, 1000);
    });

    return observable;
  }
}
