import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from 'src/settings';
import { Observable } from 'rxjs/internal/Observable';

const BASE_URL = `${settings.PROVEDOR_DADOS_API}`;
@Injectable()
export class ResidenciasService {

    constructor(private serviceHelper: ServiceHelpersService) { }

    public BuscarBeneficiosResidencias = (): Observable<any> =>
        this.serviceHelper.getWithoutAuthority<any>(BASE_URL,'/api/Residencias/CalculoBenificiosB2C', null);
}
