/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-edp/ngx-edp.ngfactory";
import * as i3 from "ngx-edp";
import * as i4 from "../../node_modules/ngx-edp-header/ngx-edp-header.ngfactory";
import * as i5 from "ngx-edp-header";
import * as i6 from "@angular/common";
import * as i7 from "./shared/components/footer/footer.components.ngfactory";
import * as i8 from "./shared/components/footer/footer.components";
import * as i9 from "../../node_modules/ngx-edp-cookies-consent/ngx-edp-cookies-consent.ngfactory";
import * as i10 from "ngx-edp-cookies-consent";
import * as i11 from "ngx-cookie-service";
import * as i12 from "@angular/router";
import * as i13 from "./app.component";
import * as i14 from "angular-oauth2-oidc";
import * as i15 from "./_services/loading-type.service";
import * as i16 from "./_services/usuario.service";
import * as i17 from "./_services/checkout.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 245760, null, 0, i3.LoadingComponent, [i3.ɵd, i3.ɵcg], { readyState: [0, "readyState"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readyState; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 245760, null, 0, i3.LoadingComponent, [i3.ɵd, i3.ɵcg], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "lib-app-header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).positionMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AppHeaderComponent_0, i4.RenderType_AppHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.AppHeaderComponent, [i5.AppHeaderService], { navigationLink: [0, "navigationLink"], urlSmart: [1, "urlSmart"], redirectSegment: [2, "redirectSegment"], logoRedirectUrl: [3, "logoRedirectUrl"], usuarioAtual: [4, "usuarioAtual"], qtdNotificacao: [5, "qtdNotificacao"], qtdItensCarrinho: [6, "qtdItensCarrinho"], checkoutUrl: [7, "checkoutUrl"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigationLink; var currVal_1 = _co.urlSolar; var currVal_2 = _co.redirectSegment; var currVal_3 = _co.logoRedirectUrl; var currVal_4 = _co.usuarioAtual$; var currVal_5 = i1.ɵunv(_v, 1, 5, i1.ɵnov(_v, 2).transform(_co.qtdNotificacao$)); var currVal_6 = _co.qtdItensCarrinho; var currVal_7 = _co.checkoutUrl; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i8.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-cookies-consent", [], null, null, null, i9.View_CookiesConsentComponent_0, i9.RenderType_CookiesConsentComponent)), i1.ɵdid(1, 114688, null, 0, i10.CookiesConsentComponent, [i11.CookieService], { settings: [0, "settings"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readyState; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.readyState; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.navigationLoaded; _ck(_v, 5, 0, currVal_2); _ck(_v, 7, 0); var currVal_3 = _co.navigationLoaded; _ck(_v, 9, 0, currVal_3); var currVal_4 = (!_co.isLoginCallBack && _co.navigationLoaded); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i12.Router, i14.OAuthService, i5.AppHeaderService, i15.LoadingTypeService, i3.EdpAuthConfigService, i16.UsuarioService, i17.CheckoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
