<section class="termos-condicoes">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-6 box-termos">
                <p class="text-uppercase condicoes">Condições gerais</p>
                <p class="descricao-termo">
                    <a [href]="url" class="acesse-aqui">Acesse
                        aqui</a>
                        os termos e condições das nossas campanhas.
                </p>
            </div>
        </div>
    </div>

</section>