import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-grandes-empresas',
  templateUrl: './header-grandes-empresas.component.html',
  styleUrls: ['./header-grandes-empresas.component.scss']
})
export class HeaderGrandesEmpresasComponent {

  constructor() { }

}
