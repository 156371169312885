import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TermosECondicoesServico } from 'src/app/_services/termos-e-condicoes.service';
import { SaveRouteOrigin } from 'src/app/utils/save-route/save-route';

@Component({
  selector: 'app-termos-e-condicoes-b2b',
  templateUrl: './termos-e-condicoes-b2b.component.html',
  styleUrls: ['./termos-e-condicoes-b2b.component.scss']
})
export class TermosECondicoesB2bComponent implements OnInit {
  public title;
  public field_corpo;
  public field_descricao;

  constructor(private titulo: Title, private termosServico: TermosECondicoesServico) {
    titulo.setTitle('EDP Smart - Energia Solar - Nossos Termos e Condições para Empresas');
  }

  ngOnInit() {
    SaveRouteOrigin(window.location.href);
    this.termosServico.obterTermosECondicoesB2B().subscribe(x => {
      let termosECondicoes = JSON.parse(x.dado);
      this.field_corpo = termosECondicoes[0].field_corpo;
      this.field_descricao = termosECondicoes[0].field_descricao;
    });
  }
}
