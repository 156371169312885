import { Injectable } from '@angular/core';
import { ServiceHelpersService } from './service-helper.service';
import settings from 'src/settings';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const BASE_URL = `${settings.PROVEDOR_DADOS_API}/api/PoliticaDePrivacidade`;
@Injectable()
export class PoliticaDePrivacidadeServico {
    constructor(private serviceHelpers: ServiceHelpersService) { }

    public obterPoliticaDePrivacidade() {
        return this.fetchByUrlSegment('obterPoliticaDePrivacidade', null);
    }

    private fetchByUrlSegment(url: string, params: HttpParams): Observable<any> {
        return this.serviceHelpers.getWithoutAuthority<any>(BASE_URL, url, params);
    }
}
