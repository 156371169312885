import { HttpClient, HttpHeaders, } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import settings from "src/settings";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var CodigoAreaNegocio;
(function (CodigoAreaNegocio) {
    CodigoAreaNegocio[CodigoAreaNegocio["Eficiencia"] = 448780005] = "Eficiencia";
    CodigoAreaNegocio[CodigoAreaNegocio["Energia"] = 448780006] = "Energia";
    CodigoAreaNegocio[CodigoAreaNegocio["Mobilidade"] = 448780003] = "Mobilidade";
    CodigoAreaNegocio[CodigoAreaNegocio["Solar"] = 448780004] = "Solar";
})(CodigoAreaNegocio || (CodigoAreaNegocio = {}));
export var CodigoAResponsabilidadeComercial;
(function (CodigoAResponsabilidadeComercial) {
    CodigoAResponsabilidadeComercial[CodigoAResponsabilidadeComercial["S2C"] = 448780000] = "S2C";
    CodigoAResponsabilidadeComercial[CodigoAResponsabilidadeComercial["RepresentanteComercial"] = 448780001] = "RepresentanteComercial";
    CodigoAResponsabilidadeComercial[CodigoAResponsabilidadeComercial["ComercialInterno"] = 448780002] = "ComercialInterno";
    CodigoAResponsabilidadeComercial[CodigoAResponsabilidadeComercial["SiteB2B"] = 448780004] = "SiteB2B";
})(CodigoAResponsabilidadeComercial || (CodigoAResponsabilidadeComercial = {}));
export var Status;
(function (Status) {
    Status[Status["Novo"] = 1] = "Novo";
    Status[Status["EmEnriquecimento"] = 2] = "EmEnriquecimento";
    Status[Status["Enriquecido"] = 448780002] = "Enriquecido";
})(Status || (Status = {}));
export class ClientePotencialService {
    constructor(http) {
        this.http = http;
        this.token = "";
        this.returnAPI = "";
    }
    gerarLead(dadosCliente) {
        const url = `${settings.URL_CLIENTE_POTENCIAL}/v3/clientepotencial`;
        return this.getToken().pipe(mergeMap((data) => {
            this.token = data;
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.token}`,
                "Ocp-Apim-Subscription-Key": settings.APIM_KEY_CLIENTE_POTENCIAL,
            });
            return this.http
                .post(url, JSON.stringify(dadosCliente), {
                headers: headers,
            })
                .pipe(map((x) => (x ? x.toString() : null)));
        }));
    }
    getSetores() {
        const url = `${settings.URL_CLIENTE_POTENCIAL}/v1/setores`;
        return this.getToken().pipe(mergeMap((data) => {
            this.token = data;
            const headers = new HttpHeaders({
                Authorization: `Bearer ${this.token}`,
                "Ocp-Apim-Subscription-Key": settings.APIM_KEY_CLIENTE_POTENCIAL,
            });
            return this.http
                .get(url, { headers: headers })
                .pipe(map((x) => x));
        }));
    }
    getToken() {
        const header = new HttpHeaders({
            "user-id": settings.USER_ID_CLIENTE_POTENCIAL,
            "Ocp-Apim-Subscription-Key": settings.APIM_KEY_CLIENTE_POTENCIAL,
        });
        const requestOptions = { headers: header };
        const url = `${settings.URL_CLIENTE_POTENCIAL}/token`;
        return this.http.get(url, requestOptions).pipe(map((x) => x.toString()));
    }
}
ClientePotencialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientePotencialService_Factory() { return new ClientePotencialService(i0.ɵɵinject(i1.HttpClient)); }, token: ClientePotencialService, providedIn: "root" });
