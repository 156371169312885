import { Injectable } from '@angular/core';
import { ServiceHelpersService } from './service-helper.service';
import settings from 'src/settings';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const BASE_URL = `${settings.PROVEDOR_DADOS_API}/api/TermosECondicoes`;
@Injectable()
export class TermosECondicoesServico {
    constructor(private serviceHelpers: ServiceHelpersService) { }

    public obterTermosECondicoesB2B() {
        return this.fetchByUrlSegment('ObterTermosECondicoesB2B', null);
    }

    public obterTermosECondicoesB2C() {
        return this.fetchByUrlSegment('ObterTermosECondicoesB2C', null);
    }

    private fetchByUrlSegment(url: string, params: HttpParams): Observable<any> {
        return this.serviceHelpers.getWithoutAuthority<any>(BASE_URL, url, params);
    }
}
