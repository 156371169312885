import { ServiceHelpersService, ContentTypeEnum } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from '../../settings';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PotenciaService {
  constructor(private serviceHelpers: ServiceHelpersService) { }

  public obterPotenciaAtual = (usinaId: string): Observable<any> =>
    this.fetchPotenciaByUrlSegment(`/api/Potencia/ObterPotenciaAtual?usinaId=${usinaId}`, null)

  private fetchPotenciaByUrlSegment(url: string, params: HttpParams): Observable<any> {
    return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, url, params);
  }
}
