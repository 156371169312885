<div class="container">
    <div class="termos">
        <p><strong>TERMOS E CONDI&Ccedil;&Otilde;ES DA AQUISI&Ccedil;&Atilde;O DE </strong><strong>GERADOR SOLAR
                FOTOVOLTAICO</strong> <strong>E ATIVIDADES RELACIONADAS</strong></p>
        <p><strong>POR FAVOR, LEIA COM ATEN&Ccedil;&Atilde;O ESTES TERMOS ANTES DE ADQUIRIR O </strong><strong>GERADOR
                SOLAR FOTOVOLTAICO</strong><strong>.</strong></p>
        <p>A <strong>EDP Smart</strong> &eacute; uma marca registrada pela <strong>EDP Brasil</strong>, a qual tem como
            objetivo a oferta de servi&ccedil;os, a comercializa&ccedil;&atilde;o e solu&ccedil;&otilde;es em energia,
            atrav&eacute;s de suas empresas, dentre elas a EDP GRID &ndash; Gest&atilde;o de Redes Inteligentes de
            Distribui&ccedil;&atilde;o S.A. (&ldquo;<strong>EDP Grid</strong>&rdquo;), pertencente ao <strong>Grupo
                EDP</strong>.</p>
        <p>Ao adquirir o Gerador Solar Fotovoltaico (a operar na modalidade de microgera&ccedil;&atilde;o
            distribu&iacute;da), o Cliente concorda com os presentes termos e condi&ccedil;&otilde;es, que
            reger&atilde;o o relacionamento contratual que ser&aacute; estabelecido, a partir da ades&atilde;o ao
            contrato de aquisi&ccedil;&atilde;o de gerador solar fotovoltaico (&ldquo;Contrato&rdquo;), pelo Cliente. Em
            caso de discord&acirc;ncia do Cliente com quaisquer dos termos e condi&ccedil;&otilde;es estabelecidas
            nestes termos, n&atilde;o &eacute; recomend&aacute;vel a ades&atilde;o ao contrato.</p>
        <p><strong>A EDP Grid poder&aacute; alterar os Termos relativos &agrave; aquisi&ccedil;&atilde;o do
            </strong><strong>Gerador Solar Fotovoltaico</strong> <strong>e atividades relacionadas a qualquer momento,
                desde que os torne p&uacute;blicos em momento pr&eacute;vio &agrave; venda da mercadoria ao
                Cliente.</strong></p>
        <ul>
            <li><strong>Da aquisi&ccedil;&atilde;o do </strong><strong>Gerador Solar Fotovoltaico</strong><strong> e
                    realiza&ccedil;&atilde;o das atividades relacionadas</strong>
                <ul>
                    <li>A ades&atilde;o ao Contrato caracteriza-se pelo preenchimento da Ficha de Cadastro em um dos
                        Canais de Contrata&ccedil;&atilde;o e/ou pelo aceite dos termos da Proposta Comercial, do
                        Contrato e dos presentes TERMOS E CONDI&Ccedil;&Otilde;ES DA AQUISI&Ccedil;&Atilde;O DE GERADOR
                        SOLAR FOTOVOLTAICO E ATIVIDADES RELACIONADAS.</li>
                    <li>A ades&atilde;o ao Contrato depender&aacute; de aprova&ccedil;&atilde;o junto &agrave; entidade
                        financeira.</li>
                    <li>A redu&ccedil;&atilde;o de at&eacute; 95% da conta de energia do Cliente constitui-se em
                        redu&ccedil;&atilde;o estimada, calculada considerando a Energia Compens&aacute;vel, sendo:</li>
                </ul>
            </li>
        </ul>
        <p>Energia Compens&aacute;vel = Quantidade de Energia Consumida no M&ecirc;s &ndash; Taxa de Disponibilidade.
        </p>
        <ul>
            <li>A economia na conta de energia do Cliente pode variar em fun&ccedil;&atilde;o da gera&ccedil;&atilde;o
                de eletricidade do Gerador Solar Fotovoltaico, a depender das caracter&iacute;sticas f&iacute;sicas do
                local da instala&ccedil;&atilde;o, irradia&ccedil;&atilde;o mensal e outros fatores externos.</li>
            <li>Os valores da mensalidade e parcelamento do Gerador Solar Fotovoltaico podem variar em
                fun&ccedil;&atilde;o do montante de energia contratado e est&atilde;o sujeitos &agrave;
                aprova&ccedil;&atilde;o de entidade financeira.</li>
            <li>Para uma resid&ecirc;ncia, as parcelas de a partir de R$ 380,00 ao m&ecirc;s foram calculadas
                considerando um investimento total de R$ 16.400,00.</li>
            <li>Para atividade empresarial e pequenas empresas, as parcelas de a partir de R$ 510,00 foram calculadas
                considerando um investimento total de R$ 21.800,00.</li>
        </ul>
        <ul>
            <li><strong>O Cliente n&atilde;o poder&aacute; ceder, nem de qualquer outro modo transferir, seu cadastro a
                    nenhuma outra pessoa ou entidade. </strong></li>
            <li><strong>Em determinadas situa&ccedil;&otilde;es, o Cliente poder&aacute; ser solicitado(a) a fornecer
                    documentos de identifica&ccedil;&atilde;o, com foto, para que as atividades relacionadas &agrave;
                    aquisi&ccedil;&atilde;o do </strong><strong>Gerador Solar Fotovoltaico</strong> <strong>sejam
                    realizadas. O Cliente concorda que poder&aacute; ter a realiza&ccedil;&atilde;o das atividades
                    condicionada ao fornecimento dos referidos documentos, quando solicitados.</strong></li>
            <li><strong>Cadastro</strong>
                <ul>
                    <li>O Cliente ser&aacute; respons&aacute;vel pela veracidade de todos os dados por ele fornecidos
                        &agrave; EDP Grid, ficando respons&aacute;vel, portanto, pela sua exatid&atilde;o e
                        autenticidade.</li>
                    <li>&Eacute; dever do Cliente manter seus dados atualizados.</li>
                </ul>
            </li>
            <li><strong>Descri&ccedil;&atilde;o das atividades</strong></li>
        </ul>
        <p><strong>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Visita T&eacute;cnica</strong>. Visita ao local do Cliente
            para levantamento das informa&ccedil;&otilde;es t&eacute;cnicas necess&aacute;rias &agrave;
            elabora&ccedil;&atilde;o do projeto executivo e documenta&ccedil;&atilde;o da homologa&ccedil;&atilde;o
            junto &agrave; distribuidora de energia el&eacute;trica.</p>
        <p><strong>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Elabora&ccedil;&atilde;o do Projeto</strong>.
            Elabora&ccedil;&atilde;o do projeto executivo do Gerador Solar Fotovoltaico e da documenta&ccedil;&atilde;o,
            a serem apresentados &agrave; distribuidora de energia el&eacute;trica para aprova&ccedil;&atilde;o do
            parecer de acesso e execu&ccedil;&atilde;o da instala&ccedil;&atilde;o.</p>
        <p><strong>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Entrega</strong>. Entrega dos equipamentos, tais como
            m&oacute;dulos fotovoltaicos, inversores, <em>stringboxes</em>, dentre outros, no local da
            instala&ccedil;&atilde;o.</p>
        <p><strong>5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Instala&ccedil;&atilde;o e Comissionamento</strong>. Esta
            atividade compreende a instala&ccedil;&atilde;o completa do Gerador Solar Fotovoltaico, inclu&iacute;dos
            todos os seus componentes e a&ccedil;&otilde;es de configura&ccedil;&otilde;es, testes e acionamentos para
            garantir sua opera&ccedil;&atilde;o &oacute;tima.</p>
        <p><strong>5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Homologa&ccedil;&atilde;o</strong>. Envio &agrave;
            distribuidora de energia el&eacute;trica da documenta&ccedil;&atilde;o necess&aacute;ria, acompanhamento do
            processo a fim de obter o parecer de acesso para a unidade consumidora e contato com a distribuidora de
            energia el&eacute;trica para realiza&ccedil;&atilde;o de vistoria e troca de medidor convencional para
            medidor bidirecional.</p>
        <p><strong>5.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Exclus&otilde;es</strong>.</p>
        <p><strong>N&atilde;o est&atilde;o inclu&iacute;dos no escopo do Contrato:</strong></p>
        <p><strong>(a) quaisquer custos n&atilde;o explicitamente previstos, ainda que direta ou indiretamente
                relacionados ao projeto;</strong></p>
        <p><strong>(b) quaisquer refor&ccedil;os estruturais ou estruturas adicionais que eventualmente sejam
                necess&aacute;rias para suportar a carga adicional em decorr&ecirc;ncia da instala&ccedil;&atilde;o do
            </strong><strong>Gerador Solar Fotovoltaico</strong> <strong>(i.e. ajustes no padr&atilde;o de entrada, nas
                obras de alvenaria e/ou no telhado/local da instala&ccedil;&atilde;o). Esses trabalhos adicionais
                est&atilde;o sujeitos &agrave; aprova&ccedil;&atilde;o do Cliente;</strong></p>
        <p><strong>(c) quaisquer equipamentos definitivos de seguran&ccedil;a, como linha de vida permanente, que
                eventualmente sejam necess&aacute;rias para a realiza&ccedil;&atilde;o de trabalhos ap&oacute;s a
                instala&ccedil;&atilde;o do </strong><strong>Gerador Solar Fotovoltaico</strong><strong>, tais como
                servi&ccedil;os de manuten&ccedil;&atilde;o.</strong></p>
        <p><strong>5.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A EDP Grid disponibilizar&aacute; ao Cliente chaves de acesso
                <em>online</em>, oferecidas pela empresa fabricante dos inversores, para monitoramento remoto dos dados
                de gera&ccedil;&atilde;o. Ser&aacute; de responsabilidade do Cliente a obten&ccedil;&atilde;o de
                servi&ccedil;o de internet <em>wi-fi</em> para a utiliza&ccedil;&atilde;o do monitoramento remoto dos
                inversores</strong>.</p>
        <p><strong>5.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>Para a realiza&ccedil;&atilde;o das atividades de
            elabora&ccedil;&atilde;o de projeto e instala&ccedil;&atilde;o e comissionamento, a EDP Grid poder&aacute;
            subcontratar empresas parceiras.</p>
        <p><strong>6 Agendamento das atividades a serem realizadas </strong></p>
        <p><strong>6.1</strong> A visita t&eacute;cnica, a entrega e instala&ccedil;&atilde;o do Gerador Solar
            Fotovoltaico dever&atilde;o ser agendados, por meio do n&uacute;mero Canal Telef&ocirc;nico (0800 721 5044)
            ou aplicativo disponibilizado pela EDP Grid.</p>
        <p><strong>6.2 &Eacute; de inteira responsabilidade do Cliente observar e informar &agrave; EDP Grid quanto
                &agrave;s regras de acesso e limita&ccedil;&otilde;es eventualmente impostas pelo seu Condom&iacute;nio
                e Vizinhan&ccedil;a.</strong></p>
        <p><strong>6.3 Nos termos do item 6.1 acima, motivos de caso fortuito ou for&ccedil;a maior,
                imprevis&iacute;veis e os quais as Partes n&atilde;o poderiam evitar, </strong>tais como chuvas fortes,
            acidentes ou greves na regi&atilde;o do local da instala&ccedil;&atilde;o, poder&atilde;o impossibilitar a
            realiza&ccedil;&atilde;o das atividades agendadas na data e hor&aacute;rio previstos. Nessa hip&oacute;tese,
            as atividades ser&atilde;o reagendadas em outra data, indicada pelo Cliente, dentro da agenda de
            disponibilidade da EDP Grid, sem custos adicionais</p>
    </div>
</div>