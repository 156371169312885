<footer class="footer">
    <div class="footer-first-line">
      <div class="container-footer container-line container-first-line">
        <div class="institucional links-footer">
          <span>INSTITUCIONAL</span>
          <a [href]="UrlGrupoEdp" target="_blank">Grupo EDP</a>
          <a [href]="UrlTrabalheConosco"  target="_blank">Trabalhe conosco</a>
        </div>
        <div class="atendimento links-footer">
          <span>ATENDIMENTO</span>
          <a [href]="urlFaq">FAQ</a>
          <a [href]="urlContato">SAC</a>
          <!-- <a [href]="urlContato">Protocolo de Atendimento</a> -->
          <a [href]="urlContato">Contatos</a>
        </div>
        <div class="link-uteis links-footer">
          <span>LINKS ÚTEIS</span>
          <a [href]="urlPoliticaPrivacidade">Política de privacidade</a>
          <a [href]="urlPoliticaCookie">Política de cookies</a>
          <a [href]="urlSeguranca">Termos de uso</a>
        </div>
        <div class="edp-smart">
          <div class="log-edp-smart">
            <object data="/assets/icons/logo-horizontal-monogram.svg" type="image/svg+xml"></object>
          </div>
          <span>©Copyright {{ano}} - EDP Smart.</span>
          <span>Todos os direitos reservados</span>
          <div>
            <ul class="footer-social-media">
              <li>
                <a href="https://www.youtube.com/channel/UCmEehegCw9s1aoadJ8YzSew" target="_blank" class="btn-edp-footer alt-02 icon">
                  <i class="icon-bas-006"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/edpbrasil/" target="_blank" class="btn-edp-footer alt-02 icon">
                  <i class="icon-bas-005"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/edpbrasil/" target="_blank" class="btn-edp-footer alt-02 icon">
                  <i class="icon-bas-003"></i>
                </a>
              </li>
              <li>
                <a href="https://pt-br.facebook.com/edpbr/" target="_blank" class="btn-edp-footer alt-02 icon">
                  <i class="icon-bas-002"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-second-line">
      <div class="container-footer container-line container-second-line">
        <div class="edp-infos">
          <p class="edp-infos-desk">
            <span><span class="bold">EDP SMART SERVICOS S/A</span> | CNPJ 02.154.070/0008-04 </span>
            <span>Rua Werner Von Siemens, 111, Conjunto 22, Bloco A, Sala 11 - Lapa de Baixo, São Paulo - SP, 05069-900 </span>
            <span>Horário de atendimento: Segunda à sábado, das 8h às 20h40 </span>
            <span>De qualquer cidade do Brasil, ligue para 0800 721 5044</span>
          </p>
          <p class="edp-infos-mobile">
            <span><span class="bold">EDP SMART SERVICOS S/A</span></span> 
            <span>CNPJ 02.154.070/0008-04 </span>
            <span>Rua Werner Von Siemens, 111, Conjunto 22, Bloco A, Sala 11</span>
            <span>Lapa de Baixo, São Paulo - SP, 05069-900 </span>
            <span>Horário de atendimento:</span>
            <span>Segunda à sábado, das 8h às 20h40 </span>
            <span>De qualquer cidade do Brasil, ligue</span>
            <span>para 0800 721 5044</span>
          </p>
        </div>
        
      </div>
    </div>
  </footer>