import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from '../../settings';
import { Observable, BehaviorSubject } from 'rxjs';
import { IPessoaFisica, IProvedorResponse, IReceberInformacoes } from 'ngx-edp/lib/types';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UsuarioService {
    constructor(
        private serviceHelpers: ServiceHelpersService,
        private cookieService: CookieService
    ) { }

    public usuario: any = null;
    public usuarioAtual: BehaviorSubject<IPessoaFisica> = new BehaviorSubject(null);

    public obterDadosUsuario(): Observable<any> {
        return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, 'api/Usuario/dados', null, true);
    }

    public obterUsuarioAutenticado(): Observable<any> {
      return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, 'api/Usuario/obterDadosUsuarioLogado', null, true);
    }

    public ObterDadosPessoaFisica(): Observable<any> {
        return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, 'api/Usuario/obterDadosUsuarioPessoaFisica', null, true);
    }

    public contarNotificacoesUsuario(): Observable<any> {
        return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, 'api/usuario/contarNotificacoes');
    }

    public obterNotificacoesUsuario(): Observable<any> {
        return this.serviceHelpers.get<any>(settings.PROVEDOR_DADOS_API, 'api/usuario/obterNotificacoes');
    }

    public excluirNotificacaoUsuario(data): Observable<any> {
        return this.serviceHelpers.put<any>(settings.PROVEDOR_DADOS_API, 'api/usuario/excluirNotificacao', data);
    }

    public marcarLidaUsuario(data): Observable<any> {
        return this.serviceHelpers.put<any>(settings.PROVEDOR_DADOS_API, 'api/usuario/marcarLida', data);
    }

    public obterDadosUsuarioSSO(token: string): Observable<any> {
        return this.serviceHelpers.getWithoutAuthority<any>(settings.PROVEDOR_DADOS_SSO, `/Usuario/ObterDadosUsuario?usuario=${token}`);
    }

    public atualizarReceberInformacoes(receberInfos: IReceberInformacoes) {
        this.cookieService.set('aceitarReceberInformacoes', `${receberInfos.aceitarReceber}`, null, '/', settings.COOKIE_DOMAIN);
        return this.serviceHelpers.postWithoutAuthority<any>(settings.PROVEDOR_DADOS_API, 'api/Usuario/atualizar-receber', receberInfos);
      }
}
