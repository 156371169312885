import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  template: ''
})
export class LoginComponent implements OnInit {

  constructor(private oauthService: OAuthService) { }

  ngOnInit(): void { this.login(); }

  public login() {
    this.oauthService.initImplicitFlow('login');
  }

}
