import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-termos-condicoes',
  templateUrl: './termos-condicoes.component.html',
  styleUrls: ['./termos-condicoes.component.scss']
})
export class TermosCondicoesComponent implements OnInit {
  @Input() url: string;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  redirecionarTermo() {
    this.router.navigateByUrl(this.url);
  }
}
