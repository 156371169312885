import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  private URL_REDIRECT = 'url_redirect';
  private ROUTE_ORIGIN = 'route_origin';

  constructor(
    private router: Router,
    private oauthService: OAuthService) { }

  canActivate = () => this.redirectOnInvalidToken();

  canActivateChild = () => this.redirectOnInvalidToken();

  redirectOnInvalidToken() {
    if (this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) {
      if (localStorage[this.URL_REDIRECT] !== '') {
        let url = localStorage[this.URL_REDIRECT];
        localStorage[this.URL_REDIRECT] = '';
        window.location.href = url;
      }
      return true;
    }

    let URL = window.location.href.toString().replace('/auth','');
    if(localStorage.getItem(this.ROUTE_ORIGIN) == null || localStorage.getItem(this.ROUTE_ORIGIN) == ''){
      localStorage.setItem(this.ROUTE_ORIGIN, URL);
    }

    localStorage[this.URL_REDIRECT] = window.location.href.replace('/auth','');
    if(localStorage[this.URL_REDIRECT] != localStorage[this.ROUTE_ORIGIN]){
      localStorage[this.ROUTE_ORIGIN] = localStorage[this.URL_REDIRECT];
    }

    this.router.navigate(['/login']);
    return false;
  }
}
