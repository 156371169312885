/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./login-callback.component";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "@angular/router";
var styles_LoginCallbackComponent = [];
var RenderType_LoginCallbackComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginCallbackComponent, data: {} });
export { RenderType_LoginCallbackComponent as RenderType_LoginCallbackComponent };
export function View_LoginCallbackComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LoginCallbackComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LoginCallbackComponent_0, RenderType_LoginCallbackComponent)), i0.ɵdid(1, 114688, null, 0, i1.LoginCallbackComponent, [i2.OAuthService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginCallbackComponentNgFactory = i0.ɵccf("ng-component", i1.LoginCallbackComponent, View_LoginCallbackComponent_Host_0, {}, {}, []);
export { LoginCallbackComponentNgFactory as LoginCallbackComponentNgFactory };
