<div class="container">
    <div class="termos">
        <div class="text-center">
            <h1>Pol&iacute;tica de Privacidade</h1>
        </div>
        <br>
        <br>
        <p><strong>EDP ENERGIAS DO BRASIL S.A.</strong>, com sede na cidade de S&atilde;o Paulo, estado de S&atilde;o
            Paulo, Rua Gomes de Carvalho n&ordm; 1.996, 7&ordm; andar, Vila Olimpia, inscrita no CNPJ sob o n&ordm;
            03.983.431/0001-03 e demais empresas do Grupo EDP que estejam sob o seu controle
            (&ldquo;<strong>EDP</strong>&rdquo;), tem como compromisso o respeito pela privacidade de seus
            usu&aacute;rios, bem como a prote&ccedil;&atilde;o e a seguran&ccedil;a dos seus dados pessoais.</p>
        <p>A presente Pol&iacute;tica de Privacidade (&ldquo;<strong>Pol&iacute;tica</strong>&rdquo;) tem como objetivo
            esclarecer quais informa&ccedil;&otilde;es s&atilde;o coletadas dos usu&aacute;rios de nosso site&nbsp;<a
                href="https://www.edpsmart.com.br">https://www.edpsmart.com.br</a>&nbsp;&ndash; e respectivos produtos e
            servi&ccedil;os (&ldquo;<strong>Produtos</strong>&rdquo; e &ldquo;<strong>Servi&ccedil;os</strong>&rdquo;)
            &ndash; e de que forma esses dados s&atilde;o tratados.</p>
        <p>Para oferecer os Produtos e Servi&ccedil;os aos seus clientes, a EDP, por meio de seus sites e aplicativos,
            coleta diversos dados e informa&ccedil;&otilde;es, visando, sobretudo, proporcionar uma experi&ecirc;ncia
            cada vez melhor para voc&ecirc;.</p>
        <p>A EDP reconhece que a sua privacidade &eacute; muito importante, portanto tomamos todas as medidas
            poss&iacute;veis, dentro das melhores pr&aacute;ticas e solu&ccedil;&otilde;es existentes, para
            proteg&ecirc;-la. Nesse sentido, a presente Pol&iacute;tica visa inform&aacute;-lo como os seus dados
            ser&atilde;o coletados e tratados.</p>
        <p>Este documento &eacute; parte integrante do Termo e Condi&ccedil;&otilde;es de Uso, que cont&eacute;m
            informa&ccedil;&otilde;es sobre o uso, isen&ccedil;&otilde;es e limita&ccedil;&otilde;es de responsabilidade
            com rela&ccedil;&atilde;o &agrave; utiliza&ccedil;&atilde;o dos sites e aplicativos da EDP.</p>
        <p>Esta Pol&iacute;tica foi redigida de forma simples e acess&iacute;vel, justamente para que voc&ecirc; possa
            ler e entender como utilizamos os seus dados para proporcionar uma experi&ecirc;ncia segura e
            confort&aacute;vel no uso dos Produtos e Servi&ccedil;os ofertados.</p>
        <p><strong>O aceite desta Pol&iacute;tica ser&aacute; considerado formalizado no momento em que voc&ecirc;
                inserir as suas informa&ccedil;&otilde;es ou se cadastrar em nossos sites ou aplicativos, para usufruir
                dos Produtos e Servi&ccedil;os ofertados pela EDP, mesmo que de forma gratuita. </strong></p>
        <p><strong>Isso indicar&aacute; que voc&ecirc; est&aacute; ciente e em total acordo com a forma como
                utilizaremos as suas informa&ccedil;&otilde;es e seus dados.</strong></p>
        <p><strong>Caso n&atilde;o concorde com esta Pol&iacute;tica, por favor, n&atilde;o continue o seu procedimento
                de cadastro, para a solicita&ccedil;&atilde;o dos Produtos e Servi&ccedil;os fornecidos ou prestados
                pela EDP. Todavia, pedimos a gentileza de informar &agrave; EDP acerca da sua eventual
                discord&acirc;ncia para que possamos utilizar seus fundamentos para avaliar a revis&atilde;o desta
                Pol&iacute;tica.</strong></p>
        <p>A presente Pol&iacute;tica est&aacute; dividida da seguinte forma para facilitar a sua
            compreens&atilde;o:&nbsp;</p>
        <p>Qualquer d&uacute;vida em rela&ccedil;&atilde;o &agrave; presente Pol&iacute;tica poder&aacute; ser
            esclarecida entrando em contato conosco, por meio do endere&ccedil;o
            eletr&ocirc;nico&nbsp;atendimento@edpsmart.com.br, indicando como assunto &ldquo;EDP - Privacidade de dados
            pessoais&rdquo;.</p>
        <ol>
            <li><strong>INFORMA&Ccedil;&Otilde;ES QUE COLETAMOS</strong></li>
        </ol>
        <p><strong>Informa&ccedil;&otilde;es fornecidas por voc&ecirc;</strong> &ndash; Das informa&ccedil;&otilde;es
            fornecidas por voc&ecirc; com o preenchimento dos formul&aacute;rios dispon&iacute;veis em nossos sites e
            aplicativos, a EDP coletar&aacute; aquelas de identifica&ccedil;&atilde;o pessoal, como por exemplo, nome,
            telefone, e-mail, RG, CPF e endere&ccedil;o. Eventualmente, a solicita&ccedil;&atilde;o de outras
            informa&ccedil;&otilde;es poder&aacute; ser feita por meio de contato direto da EDP com os usu&aacute;rios,
            presencialmente ou via e-mail ou telefone.</p>
        <p><strong>Informa&ccedil;&otilde;es de navega&ccedil;&atilde;o no site</strong> &ndash; Quando voc&ecirc;
            acessa nossos sites e aplicativos, poder&aacute; ser instalado um &lsquo;cookie&rsquo; no seu navegador para
            identificar quantas vezes voc&ecirc; retorna ao nosso endere&ccedil;o. Nesta hip&oacute;tese, poder&atilde;o
            ser coletadas informa&ccedil;&otilde;es como endere&ccedil;o IP, localiza&ccedil;&atilde;o
            geogr&aacute;fica, fonte de refer&ecirc;ncia, tipo de navegador, dura&ccedil;&atilde;o da visita e
            p&aacute;ginas visitadas. Para maiores informa&ccedil;&otilde;es acesse a nossa <strong>Pol&iacute;tica de
                Cookies.</strong></p>
        <p><strong>Hist&oacute;rico de contato</strong> &ndash; A EDP armazena informa&ccedil;&otilde;es a respeito de
            todos os contatos j&aacute; realizados com nossos usu&aacute;rios, como intera&ccedil;&otilde;es pessoais,
            via telefone ou via e-mail.</p>
        <p><strong>Dados gerados pela utiliza&ccedil;&atilde;o de nossos Servi&ccedil;os ou solicita&ccedil;&atilde;o de
                nossos Produtos</strong> &ndash; Se voc&ecirc; utilizar algum de nossos Servi&ccedil;os, ou solicitar
            nossos Produtos, a EDP poder&aacute; coletar outras informa&ccedil;&otilde;es necess&aacute;rias para a
            execu&ccedil;&atilde;o do contrato de presta&ccedil;&atilde;o de Servi&ccedil;o ou fornecimento do Produto.
            Nesse caso, poder&atilde;o ser coletados dados de contato como seu nome completo, e-mail e telefone, RG e
            CPF, dados locacionais como pa&iacute;s, cidade e estado, logradouro, n&uacute;mero do logradouro e
            complemento.</p>
        <ol start="2">
            <li><strong>PARA QUE USAMOS SUAS INFORMA&Ccedil;&Otilde;ES</strong></li>
        </ol>
        <p>Todos os dados coletados s&atilde;o utilizados para a presta&ccedil;&atilde;o de Servi&ccedil;os ou
            fornecimento de Produtos, tendo a sua privacidade respeitada. Por isso, todos os dados e
            informa&ccedil;&otilde;es sobre voc&ecirc; ser&atilde;o tratadas como confidenciais, e somente utilizadas
            para os fins aqui descritos e autorizados por voc&ecirc;, principalmente para que voc&ecirc; possa utilizar
            nossos Servi&ccedil;os e Produtos de forma plena, visando sempre melhorar a sua experi&ecirc;ncia como
            usu&aacute;rio. Dessa forma, poderemos personalizar o Servi&ccedil;o ou Produto para adequ&aacute;-los, cada
            vez mais, &agrave;s suas prefer&ecirc;ncias e interesses. Ademais, seus dados tamb&eacute;m poder&atilde;o
            ser utilizados para criarmos e oferecermos novos Servi&ccedil;os, Produtos e funcionalidades.</p>
        <p>Para prestar Servi&ccedil;os e fornecermos Produtos, cruzaremos os dados coletados com outros que tivermos
            sobre o mesmo titular em nossas bases de dados. Em especial, utilizaremos os dados para criar perfis com
            base nos dados de seu comportamento e verificar quais s&atilde;o seus interesses, para classificar e
            qualificar de acordo com esse perfil, e para criar listas din&acirc;micas para execu&ccedil;&atilde;o de
            a&ccedil;&otilde;es de marketing direcionadas.</p>
        <p>Eventualmente, poderemos utilizar dados para finalidades n&atilde;o previstas nesta Pol&iacute;tica, mas que
            estejam dentro das suas leg&iacute;timas expectativas. O eventual uso dos seus dados para finalidades que
            n&atilde;o cumpram com essa prerrogativa ser&aacute; feito mediante autoriza&ccedil;&atilde;o que
            solicitaremos a voc&ecirc; previamente.</p>
        <p>Al&eacute;m das hip&oacute;teses citadas nesta Pol&iacute;tica, seus dados poder&atilde;o ser utilizados para
            as seguintes finalidades:</p>
        <ul>
            <li>Seu e-mail poder&aacute; ser utilizado para a opera&ccedil;&atilde;o de envio de
                informa&ccedil;&otilde;es por voc&ecirc; requisitada no preenchimento dos formul&aacute;rios
                dispon&iacute;veis em nossos sites. Tamb&eacute;m poder&aacute; ser utilizado para o envio de
                not&iacute;cias, ofertas de Produtos, Servi&ccedil;os e outras informa&ccedil;&otilde;es, sempre
                relacionadas ao tema energia e servi&ccedil;os. O e-mail ser&aacute; utilizado ainda para comunicar o
                lan&ccedil;amento de novos servi&ccedil;os ou de novos produtos da EDP e parceiros, sendo certo que o
                usu&aacute;rio pode cancelar o cadastro para o recebimento de e-mails dessa natureza a qualquer momento.
            </li>
        </ul>
        <ul>
            <li>Funcion&aacute;rios da EDP poder&atilde;o eventualmente entrar em contato via e-mail ou telefone para
                fazer pesquisas ou apresentar Produtos e Servi&ccedil;os.</li>
        </ul>
        <ul>
            <li>Poder&atilde;o ser enviadas, pela EDP a voc&ecirc;, mensagens a respeito de suporte ou servi&ccedil;o,
                como alertas, notifica&ccedil;&otilde;es e atualiza&ccedil;&otilde;es;</li>
        </ul>
        <ul>
            <li>A EDP poder&aacute; inform&aacute;-lo sobre produtos, servi&ccedil;os, promo&ccedil;&otilde;es,
                not&iacute;cias, atualiza&ccedil;&otilde;es, eventos e outros assuntos que voc&ecirc; possa ter
                interesse;</li>
        </ul>
        <ul>
            <li>A EDP poder&aacute; realizar publicidade direcionada conforme seus gostos, interesses e outras
                informa&ccedil;&otilde;es coletadas;</li>
        </ul>
        <ul>
            <li>A EDP poder&aacute; personalizar o servi&ccedil;o para adequ&aacute;-lo, cada vez mais, aos seus gostos
                e interesses;</li>
        </ul>
        <ul>
            <li>A EDP poder&aacute; utilizar seus dados para qualquer fim que voc&ecirc; autorize no momento da coleta;
            </li>
        </ul>
        <ul>
            <li>A EDP poder&aacute; utilizar seus dados para cumprir obriga&ccedil;&otilde;es legais e
                regulat&oacute;rias.</li>
        </ul>
        <ol start="3">
            <li><strong>SOBRE O ACESSO AOS SEUS DADOS PESSOAIS</strong></li>
        </ol>
        <p>O acesso &agrave;s suas informa&ccedil;&otilde;es pessoais estar&aacute; restrito apenas aos colaboradores da
            EDP, e dentre estes, somente as pessoas com as autoriza&ccedil;&otilde;es internas espec&iacute;fica para
            tanto. Eventualmente, caso a inser&ccedil;&atilde;o de suas informa&ccedil;&otilde;es se d&ecirc; em
            a&ccedil;&otilde;es criadas em parcerias, os parceiros explicitamente identificados tamb&eacute;m
            ter&atilde;o acesso &agrave; informa&ccedil;&atilde;o. Exceto se de outra forma for previsto em Lei, nenhum
            dado pessoal poder&aacute; ser divulgado publicamente sem a sua pr&eacute;via e expressa
            autoriza&ccedil;&atilde;o.</p>
        <p>Todos os seus dados s&atilde;o confidenciais e qualquer uso de tais dados estar&aacute; de acordo com a Lei e
            com a presente Pol&iacute;tica. A EDP empreender&aacute; todos os esfor&ccedil;os razo&aacute;veis de
            mercado para garantir a seguran&ccedil;a dos nossos sistemas e, consequentemente, dos seus dados pessoais.
        </p>
        <p>Todas as suas informa&ccedil;&otilde;es ser&atilde;o, sempre que poss&iacute;vel, criptografadas, caso
            n&atilde;o inviabilizem o seu uso pela EDP.</p>
        <p>Dentre outros direitos previstos em Lei, mediante requisi&ccedil;&atilde;o e a qualquer momento, voc&ecirc;
            poder&aacute; ter acesso aos seus dados pessoais armazenados em nossos sistemas, bem como solicitar a
            corre&ccedil;&atilde;o de dados incompletos, inexatos ou desatualizados.</p>
        <p>A EDP manter&aacute; os seus dados pessoais e informa&ccedil;&otilde;es somente at&eacute; quando estas forem
            necess&aacute;rias ou relevantes para as finalidades descritas nesta Pol&iacute;tica, ou em caso de
            per&iacute;odos pr&eacute;-determinados por Lei, ou at&eacute; quando estas forem necess&aacute;rias para a
            manuten&ccedil;&atilde;o de interesses leg&iacute;timos da EDP, nos termos da Lei.</p>
        <ol start="4">
            <li><strong>SOBRE O COMPARTILHAMENTO DE DADOS</strong></li>
        </ol>
        <p>A EDP poder&aacute; compartilhar os seus dados pessoais com parceiros, terceiros ou entre empresas do Grupo
            EDP, conforme for razoavelmente necess&aacute;rio para os prop&oacute;sitos estabelecidos na presente
            Pol&iacute;tica e para a devida presta&ccedil;&atilde;o de nossos servi&ccedil;os. <strong>Por meio desta
                Pol&iacute;tica, voc&ecirc; autoriza expressamente tais compartilhamentos.</strong></p>
        <p>Todas os dados, informa&ccedil;&otilde;es e conte&uacute;dos sobre voc&ecirc; podem ser considerados ativos
            no caso de negocia&ccedil;&otilde;es em que a EDP fizer parte. Portanto, nos reservamos no direito de
            incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com
            outra. <strong>Por meio desta Pol&iacute;tica voc&ecirc; concorda e est&aacute; ciente desta
                possibilidade.</strong></p>
        <p>A EDP se reserva no direito de fornecer seus dados e informa&ccedil;&otilde;es sobre voc&ecirc;, incluindo
            intera&ccedil;&otilde;es suas, caso seja requisitado judicialmente para tanto, ato necess&aacute;rio para
            que a EDP esteja em conformidade com as leis nacionais, ou caso voc&ecirc; autorize expressamente.</p>
        <ol start="5">
            <li><strong>SOBRE O CANCELAMENTO DO CADASTRO E ALTERA&Ccedil;&Atilde;O/EXCLUS&Atilde;O DE DADOS
                    PESSOAIS</strong></li>
        </ol>
        <p>Voc&ecirc; pode optar por n&atilde;o receber mais e-mails da EDP, exceto em rela&ccedil;&atilde;o aos e-mails
            essenciais para a continuidade da presta&ccedil;&atilde;o de nossos servi&ccedil;os ou mesmo aqueles que,
            por obriga&ccedil;&atilde;o legal ou regulat&oacute;ria, a EDP necessita enviar a seus usu&aacute;rios.</p>
        <p>Em todos os e-mails que enviamos h&aacute; sempre um link para solicitar o cancelamento do seu cadastro, que
            estar&aacute; dispon&iacute;vel nas &uacute;ltimas linhas dos e-mails. Ao clicar nesse link voc&ecirc;
            ser&aacute; automaticamente descadastrado da lista de contatos.</p>
        <p>&Eacute; importante mencionar que ao preencher qualquer formul&aacute;rio ou realizar qualquer tipo de
            cadastro novamente ficar&aacute; caracterizada a reinser&ccedil;&atilde;o do seu e-mail &agrave; lista de
            contatos da EDP. Portanto, a requisi&ccedil;&atilde;o de cancelamento dever&aacute; ser feita novamente caso
            seja de seu interesse.</p>
        <p>Todos os dados coletados ser&atilde;o exclu&iacute;dos de nossos servidores quando voc&ecirc; assim
            requisitar ou quando estes n&atilde;o forem mais necess&aacute;rios ou relevantes para lhe oferecermos
            Produto e Servi&ccedil;os, salvo se houver qualquer outra raz&atilde;o para a sua manuten&ccedil;&atilde;o,
            como eventual obriga&ccedil;&atilde;o legal de reten&ccedil;&atilde;o de dados ou necessidade de
            preserva&ccedil;&atilde;o destes para resguardo de direitos da EDP. Para alterar suas
            informa&ccedil;&otilde;es pessoais ou exclu&iacute;-las do nosso banco de dados, basta enviar um e-mail para
            atendimento@edpsmart.com.br.</p>
        <ol start="6">
            <li><strong>ALTERA&Ccedil;&Otilde;ES NA POL&Iacute;TICA DE PRIVACIDADE</strong></li>
        </ol>
        <p>Essa Pol&iacute;tica pode passar por atualiza&ccedil;&otilde;es. Desta forma, recomendamos visitar
            periodicamente esta p&aacute;gina para que voc&ecirc; tenha conhecimento sobre as
            modifica&ccedil;&otilde;es. Caso sejam feitas altera&ccedil;&otilde;es relevantes que ensejem novas
            autoriza&ccedil;&otilde;es suas, tornaremos p&uacute;blica a nova pol&iacute;tica de privacidade.</p>
        <p>Esta Pol&iacute;tica foi modificada pela &uacute;ltima vez em 06 de agosto de 2019.</p>
        <ol start="7">
            <li><strong>LEI APLIC&Aacute;VEL</strong></li>
        </ol>
        <p>Este documento &eacute; regido e deve ser interpretado de acordo com as leis da Rep&uacute;blica Federativa
            do Brasil. Na hip&oacute;tese de aus&ecirc;ncia de determina&ccedil;&atilde;o legal em sentido diverso, fica
            eleito o Foro da Comarca de S&atilde;o Paulo, Estado de S&atilde;o Paulo, como o competente para dirimir
            quaisquer quest&otilde;es porventura oriundas do presente documento, com expressa ren&uacute;ncia a qualquer
            outro, por mais privilegiado que seja.</p>
    </div>
</div>