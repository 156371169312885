import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-resultado',
  templateUrl: './faq-resultado.component.html',
  styleUrls: ['./faq-resultado.component.scss']
})
export class FaqResultadoComponent {
  constructor() { }
}
