import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent  {
  constructor(private router: Router) { 
    let URL = router.url.toString().replace('/auth','');
    if(localStorage.getItem("route_origin") == null || localStorage.getItem("route_origin") == ''){
      localStorage.setItem("route_origin", URL);
    }
    router.navigateByUrl(URL);
  }
}
