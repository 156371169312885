import { Observable } from "rxjs";

export interface IIBGE_UF {
  codigo_ibge: number;
  estado: string;
  sigla: string;
}

export abstract class PartnerIBGEData {
  static ibge: IIBGE_UF[] = [
    {
      codigo_ibge: 11,
      estado: "Rondônia",
      sigla: "RO",
    },
    {
      codigo_ibge: 12,
      estado: "Acre",
      sigla: "AC",
    },
    {
      codigo_ibge: 13,
      estado: "Amazonas",
      sigla: "AM",
    },
    {
      codigo_ibge: 14,
      estado: "Roraima",
      sigla: "RR",
    },
    {
      codigo_ibge: 15,
      estado: "Pará",
      sigla: "PA",
    },
    {
      codigo_ibge: 16,
      estado: "Amapá",
      sigla: "AP",
    },
    {
      codigo_ibge: 17,
      estado: "Tocantins",
      sigla: "TO",
    },
    {
      codigo_ibge: 21,
      estado: "Maranhão",
      sigla: "MA",
    },
    {
      codigo_ibge: 22,
      estado: "Piauí",
      sigla: "PI",
    },
    {
      codigo_ibge: 23,
      estado: "Ceará ",
      sigla: "CE",
    },
    {
      codigo_ibge: 24,
      estado: "Rio Grande do Norte",
      sigla: "RN",
    },
    {
      codigo_ibge: 25,
      estado: "Paraíba",
      sigla: "PB",
    },
    {
      codigo_ibge: 26,
      estado: "Pernambuco",
      sigla: "PE",
    },
    {
      codigo_ibge: 27,
      estado: "Alagoas",
      sigla: "AL",
    },
    {
      codigo_ibge: 28,
      estado: "Sergipe",
      sigla: "SE",
    },
    {
      codigo_ibge: 29,
      estado: "Bahia",
      sigla: "BA",
    },
    {
      codigo_ibge: 31,
      estado: "Minas Gerais",
      sigla: "MG",
    },
    {
      codigo_ibge: 32,
      estado: "Espírito Santo",
      sigla: "ES",
    },
    {
      codigo_ibge: 33,
      estado: "Rio de Janeiro",
      sigla: "RJ",
    },
    {
      codigo_ibge: 35,
      estado: "São Paulo",
      sigla: "SP",
    },
    {
      codigo_ibge: 41,
      estado: "Paraná",
      sigla: "PR",
    },
    {
      codigo_ibge: 42,
      estado: "Santa Catarina",
      sigla: "SC",
    },
    {
      codigo_ibge: 43,
      estado: "Rio Grande do Sul",
      sigla: "RS",
    },
    {
      codigo_ibge: 50,
      estado: "Mato Grosso do Sul",
      sigla: "MS",
    },
    {
      codigo_ibge: 51,
      estado: "Mato Grosso",
      sigla: "MT",
    },
    {
      codigo_ibge: 52,
      estado: "Goiás",
      sigla: "GO",
    },
    {
      codigo_ibge: 53,
      estado: "Distrito Federal",
      sigla: "DF",
    },
  ];

  public static getIbgeUf(sigla: string): number {
    return this.ibge.find((x) => x.sigla === sigla).codigo_ibge;
  }
}
