import { ServiceHelpersService, ContentTypeEnum } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from '../../settings';
import { Observable } from 'rxjs';

@Injectable()
export class MenuService {
  constructor(private serviceHelpers: ServiceHelpersService) { }

  obterMenus(): Observable<any> {
    return this.serviceHelpers.getWithoutAuthority<any>(settings.PROVEDOR_DADOS_API, 'api/Menu/obterMenu');
  }

}
