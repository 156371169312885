import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoadingTypeService {
    constructor() {
        this.readyState = new Subject();
        this.readyState.next(false);
    }
    update(state) {
        this.readyState.next(state);
    }
    changeReadyState() {
        return this.readyState.asObservable();
    }
}
LoadingTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingTypeService_Factory() { return new LoadingTypeService(); }, token: LoadingTypeService, providedIn: "root" });
