import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import settings from 'src/settings';
import { ContatoGrandesEmpresasB2BModel } from '../core/grandes-empresas/model/contatoGrandesEmpresasB2B.model';

const BASE_URL = `${settings.PROVEDOR_DADOS_API}/api/Empresas`;

@Injectable()
export class GrandesEmpresasService {

    constructor(private serviceHelper: ServiceHelpersService) { }

    public enviarContato = (contatoGrandesEmpresasModel: ContatoGrandesEmpresasB2BModel) =>
        this.serviceHelper.postWithoutAuthority<any>(settings.PROVEDOR_DADOS_API, '/api/Empresas/ContatoB2B', contatoGrandesEmpresasModel)
    }
