import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import settings from 'src/settings';

@Component({
  selector: 'app-mensagem-enviada',
  templateUrl: './mensagem-enviada.component.html',
  styleUrls: ['./mensagem-enviada.component.scss']
})
export class MensagemEnviadaComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  public irHome = settings.URL_SOLAR + '/empresas';

  constructor() { }

  
}
