/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./termos-condicoes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./termos-condicoes.component";
import * as i3 from "@angular/router";
var styles_TermosCondicoesComponent = [i0.styles];
var RenderType_TermosCondicoesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermosCondicoesComponent, data: {} });
export { RenderType_TermosCondicoesComponent as RenderType_TermosCondicoesComponent };
export function View_TermosCondicoesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "termos-condicoes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-md-12 col-sm-6 box-termos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "text-uppercase condicoes"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Condi\u00E7\u00F5es gerais"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "descricao-termo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "acesse-aqui"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acesse aqui"])), (_l()(), i1.ɵted(-1, null, [" os termos e condi\u00E7\u00F5es das nossas campanhas. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 7, 0, currVal_0); }); }
export function View_TermosCondicoesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-termos-condicoes", [], null, null, null, View_TermosCondicoesComponent_0, RenderType_TermosCondicoesComponent)), i1.ɵdid(1, 114688, null, 0, i2.TermosCondicoesComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermosCondicoesComponentNgFactory = i1.ɵccf("app-termos-condicoes", i2.TermosCondicoesComponent, View_TermosCondicoesComponent_Host_0, { url: "url" }, {}, []);
export { TermosCondicoesComponentNgFactory as TermosCondicoesComponentNgFactory };
