<div class="header-banner col-12">
  <div class="row">
    <div class="offset-1 col-3">
      <div>
        <h4 class="header-energia-solar-esquerda">Energia Solar</h4>
      </div>
    </div>
    <div class="col-md-6 col-sm-2 offset-2 header-texto-container">
      <label>
        <label class="header-energia-solar-titulo-direita">Soluções de energia solar EDP Smart</label>
        <label class="header-energia-solar-texto-direita">
          As soluções de Energia Solar EDP permitem à sua empresa produzir e consumir a sua própria energia, e assim, reduzir a sua conta.
        </label>
      </label>
    </div>
  </div>
</div>
<div class="row col-12 banner-central-container">
  <div class="banner-central">
    <div class="d-flex justify-content-center banner-central-linha-1">
      Energia solar EDP
    </div>
    <div class="d-flex justify-content-center banner-central-linha-2">
      Saiba qual a melhor solução para o seu negócio
    </div>
  </div>
  <div class="row col-md-12 seta-container">
    <div class="seta">
    </div>
  </div>
</div>
