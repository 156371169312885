import { Component, OnInit, HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import settings from "src/settings";

@Component({
  selector: "app-empresas",
  templateUrl: "./empresas.component.html",
  styleUrls: ["./empresas.component.scss"],
})
export class EmpresasComponent implements OnInit {
  public backgroundServicoPosition = 'center';
  public navigationPosition = 'flex-end';
  public backgroundPosition = "78% 0%";
  public iniciarAnimacao: boolean;
  public borderColor = "#EDEDED";
  public URL_CONTATO_GRANDES_EMPRESAS = '/empresas/grandes-empresas/contato';
  public URL_SIMULADOR_PEQUENAS_EMPRESAS = '/empresas/pequenas-empresas/simulador';
  public URL_O_QUE_SOLAR_EMPRESAS = '/empresas/o-que-e-solar-para-empresas';
  date = new Date();
  datecard1 = new Date("2020-03-13");
  datecard2 = new Date("2019-08-19");
  datecard3 = new Date("2019-07-29");
  datecard4 = new Date("2019-06-11");

  constructor(titulo: Title, private router: Router) {
    titulo.setTitle("EDP Smart - Energia Solar");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.onResize();
    this.isMobile();
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    let posicao = document.documentElement.getBoundingClientRect();
    if (posicao.top < -700 && posicao.top > -1600) {
      this.iniciarAnimacao = true;
    }
  }

  isMobile() {
    return window.matchMedia("screen and (max-width: 768px)").matches;
  }


  @HostListener('window:resize')
  onResize() {
    if (this.isMobile()) {
      this.navigationPosition = 'center';
      this.backgroundServicoPosition = '66%';
    } else {
      this.navigationPosition = 'flex-end';
      this.backgroundServicoPosition = 'top';
    }
  }
}
