/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-topico.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-edp/ngx-edp.ngfactory";
import * as i3 from "ngx-edp";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "./faq-topico.component";
var styles_FaqTopicoSolarComponent = [i0.styles];
var RenderType_FaqTopicoSolarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqTopicoSolarComponent, data: {} });
export { RenderType_FaqTopicoSolarComponent as RenderType_FaqTopicoSolarComponent };
export function View_FaqTopicoSolarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-faq-topico-view", [["class", "fix-margin"]], null, null, null, i2.View_ɵcs_0, i2.RenderType_ɵcs)), i1.ɵdid(1, 114688, null, 0, i3.ɵcs, [i4.Title, i3.EdpFaqService, i5.ActivatedRoute, i5.Router, i3.ɵck], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_FaqTopicoSolarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq-topico-solar", [], null, null, null, View_FaqTopicoSolarComponent_0, RenderType_FaqTopicoSolarComponent)), i1.ɵdid(1, 114688, null, 0, i6.FaqTopicoSolarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqTopicoSolarComponentNgFactory = i1.ɵccf("app-faq-topico-solar", i6.FaqTopicoSolarComponent, View_FaqTopicoSolarComponent_Host_0, {}, {}, []);
export { FaqTopicoSolarComponentNgFactory as FaqTopicoSolarComponentNgFactory };
