import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingTypeService {
  private readyState = new Subject<boolean>();
  constructor() {
    this.readyState.next(false);
   }

   update(state: boolean) {
    this.readyState.next(state);
  }

  changeReadyState(): Observable<boolean> {
    return this.readyState.asObservable();
  }
}

