import { OnInit, Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

declare var Oidc: any;
@Component({
    template: ''
})
export class LoginCallbackComponent implements OnInit {

    constructor(private oauthService: OAuthService, private router: Router) { }

    ngOnInit(): void {
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
            if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                this.oauthService.initImplicitFlow('login');
            } else {
                localStorage.setItem('url_redirect', '');
                window.location.href = localStorage.getItem("route_origin");
            }
        });
    }

}