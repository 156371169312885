import { GrandesEmpresasComponent } from "./core/grandes-empresas/grandes-empresas.component";
import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  UrlSegment,
  UrlMatchResult,
} from "@angular/router";
import { LoginComponent } from "./core/login/login.component";
import { LoginCallbackComponent } from "./core/login/login-callback.component";
import { GrandesEmpresasContatoComponent } from "./core/grandes-empresas/grandes-empresas-contato/grandes-empresas-contato.component";
import { HomeComponent } from "./core/home/residencias/home.component";
import { TermosECondicoesB2cComponent } from "./core/termos-e-condicoes/termos-e-condicoes-b2c/termos-e-condicoes-b2c.component";
import { TermosECondicoesB2bComponent } from "./core/termos-e-condicoes/termos-e-condicoes-b2b/termos-e-condicoes-b2b.component";
import { PoliticaDePrivacidadeComponent } from "./core/politica-de-privacidade/politica-de-privacidade.component";
import { MsgSucessoOrcamentoComponent } from "./shared/components/msg-sucesso-orcamento/msg-sucesso-orcamento.component";
import { AuthGuard } from "./_guards/auth-guard";
import { AuthComponent } from "./core/auth/auth.component";
import { EmpresasComponent } from "./core/home/empresas/empresas.component";
import { EdpFaqSolarComponent } from "./core/faq/faq/faq.component";
import { FaqArtigoComponent } from "./core/faq/faq-artigo/faq-artigo.component";
import { FaqResultadoComponent } from "./core/faq/faq-resultado/faq-resultado.component";
import { FaqTopicoSolarComponent } from "./core/faq/faq-topico/faq-topico.component";

const home = {
  path: "residencias",
  component: HomeComponent,
  data: {
    title: "EDP Smart - Energia Solar",
  },
};

const homeEmpresas = {
  path: "empresas",
  component: EmpresasComponent,
  data: {
    title: "EDP Smart - Energia Solar",
  },
};

const loginRoute = {
  path: "login",
  component: LoginComponent,
  data: {
    title: "Login",
  },
};

const loginCallbackRoute = {
  path: "loginCallback",
  component: LoginCallbackComponent,
  data: {
    title: "Login CallBack",
  },
};

const AuthRoute = {
  matcher: UrlAuth,
  component: AuthComponent,
  canActivate: [AuthGuard],
  data: {
    title: "Login",
  },
};

const grandesEmpresasRoute = {
  path: "empresas/pequenas-ou-grandes-empresas",
  component: GrandesEmpresasComponent,
  data: {
    title: "Pequenas ou Grandes Empresas",
  },
};

const grandesEmpresasContatoRoute = {
  path: "empresas/grandes-empresas/contato",
  component: GrandesEmpresasContatoComponent,
  data: {
    title: "Grandes Empresas",
  },
};

const agradecimentoComponent = {
  path: "empresas/pequenas-empresas/agradecimento",
  component: MsgSucessoOrcamentoComponent,
  data: {
    title: "EDP Smart - Energia Solar - Agradecimento",
  },
};

const termosECondicoesB2CRoute = {
  path: "residencias/termos-e-condicoes",
  component: TermosECondicoesB2cComponent,
  data: {
    title: "EDP Smart - Energia Solar - Nossos Termos e Condições",
  },
};

const termosECondicoesB2BRoute = {
  path: "empresas/termos-e-condicoes",
  component: TermosECondicoesB2bComponent,
  data: {
    title:
      "EDP Smart - Energia Solar - Nossos Termos e Condições para Empresas",
  },
};

const politicaDePrivacidadeB2BRoute = {
  path: "politica-de-privacidade",
  component: PoliticaDePrivacidadeComponent,
  data: {
    title: "EDP Smart - Energia Solar - Politica de Privacidade",
  },
};

const faqB2BRoute = {
  path: "empresas/faq",
  component: EdpFaqSolarComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};

const faqB2CRoute = {
  path: "residencias/faq",
  component: EdpFaqSolarComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};

const faqTopicoB2BRoute = {
  path: "empresas/faq/:topico/:topicoId",
  component: FaqTopicoSolarComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};

const faqTopicoB2CRoute = {
  path: "residencias/faq/:topico/:topicoId",
  component: FaqTopicoSolarComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};

const faqArtigoB2BRoute = {
  path: "empresas/faq/:topicoTitulo/:artigoTitulo/:artigoId",
  component: FaqArtigoComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};

const faqArtigoB2CRoute = {
  path: "residencias/faq/:topicoTitulo/:artigoTitulo/:artigoId",
  component: FaqArtigoComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};
const faqResultadoB2CRoute = {
  path: "residencias/faq/resultado/:search",
  component: FaqResultadoComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};
const faqResultadoB2BRoute = {
  path: "empresas/faq/resultado/:search",
  component: FaqResultadoComponent,
  data: {
    title: "EDP Smart - Energia Solar - FAQ",
  },
};

const SimuladorRoutes: Routes = [
  {
    path: "residencias/simulador",
    loadChildren: () =>
      import(
        "./modules/components/simulador/simulador-b2c/simulador-b2c.module"
      ).then((m) => m.SimuladorB2CModule),
  },
  {
    path: "empresas/pequenas-empresas/simulador",
    loadChildren: () =>
      import(
        "./modules/components/simulador/simulador-b2b/simulador-b2b.module"
      ).then((m) => m.SimuladorB2BModule),
  },
];

const OrcamentoRoutes: Routes = [
  {
    path: "residencias/orcamento",
    loadChildren: () =>
      import(
        "./modules/components/orcamento/orcamento-b2c/orcamento-b2c.module"
      ).then((m) => m.OrcamentoB2CModule),
  },
  {
    path: "empresas/pequenas-empresas/orcamento",
    loadChildren: () =>
      import(
        "./modules/components/orcamento/orcamento-b2b/orcamento-b2b.module"
      ).then((m) => m.OrcamentoB2BModule),
  },
];

const OQueERoutes: Routes = [
  {
    path: "empresas/o-que-e-solar-para-empresas",
    loadChildren: () =>
      import(
        "./modules/components/oque-e-solar/oque-e-solar-empresas/oque-e-solar-empresas.module"
      ).then((m) => m.OQueESolarEmpresasModule),
  },
  {
    path: "residencias/o-que-e-solar",
    loadChildren: () =>
      import(
        "./modules/components/oque-e-solar/oque-e-solar/oque-e-solar.module"
      ).then((m) => m.OqueESolarModule),
  },
  {
    path: "residencias/o-que-e-solar-para-residencias",
    loadChildren: () =>
      import(
        "./modules/components/oque-e-solar/oque-e-solar-residencias/oque-e-solar-residencias.module"
      ).then((m) => m.OqueESolarResidenciasModule),
  },
];

const redirectNull = {
  path: "null",
  redirectTo: "",
};

export function UrlAuth(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 0) {
    return null;
  }
  const param = url[url.length - 1].toString();

  if (param === "auth") {
    return { consumed: url };
  }
  return null;
}

const routes: Routes = [
  home,
  homeEmpresas,
  loginRoute,
  loginCallbackRoute,
  faqResultadoB2CRoute,
  faqResultadoB2BRoute,
  AuthRoute,
  grandesEmpresasRoute,
  grandesEmpresasContatoRoute,
  termosECondicoesB2CRoute,
  termosECondicoesB2BRoute,
  politicaDePrivacidadeB2BRoute,
  agradecimentoComponent,
  faqB2BRoute,
  faqB2CRoute,
  faqTopicoB2BRoute,
  faqTopicoB2CRoute,
  faqArtigoB2BRoute,
  faqArtigoB2CRoute,
  ...SimuladorRoutes,
  ...OQueERoutes,
  ...OrcamentoRoutes,
  redirectNull,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
