import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
    HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { JwtService } from './jwt.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorClass implements HttpInterceptor {
    constructor(private jwtService: JwtService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.withCredentials) {
            const jwt: string = this.jwtService.getToken();
            if (jwt) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + jwt
                    },
                    withCredentials: false
                });
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    window.localStorage.clear();
                    this.router.navigate(['/login']);
                }
                return throwError(error);
            })
        );
    }
}

@Injectable()
export class ServiceHelpersService {
    constructor(private http: HttpClient, private jwtService: JwtService) { }

    public post<T>(api: string, url: string, data: any, addToken: boolean = true): Observable<T> {
        return this.http
            .post<T>(this.joinEndPoint(api, url), data, { withCredentials: addToken })
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public postWithoutAuthority<T>(api: string, url: string, data: any, addToken: boolean = false): Observable<T> {
        return this.http
            .post<T>(this.joinEndPoint(api, url), data, { withCredentials: addToken })
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public postLogin<T>(api: string, url: string, data: any, addToken: boolean = true,
        contentType: ContentTypeEnum = ContentTypeEnum.JSON): Observable<T> {
        return this.http
            .post<T>(this.joinEndPoint(api, url), data, this.getOptions(data, contentType, addToken))
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public get<T>(api: string, url: string, params: HttpParams = new HttpParams(), withCredentials: boolean = true): Observable<T> {
        return this.http
            .get<T>(this.joinEndPoint(api, url), { params, withCredentials })
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public getWithoutAuthority<T>(api: string, url: string, params: HttpParams = new HttpParams(), withCredentials: boolean = false): Observable<T> {
        return this.http
            .get<T>(this.joinEndPoint(api, url), { params, withCredentials })
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public delete<T>(api: string, url: string, params: HttpParams = new HttpParams(), withCredentials: boolean = true): Observable<T> {
        return this.http
            .delete<T>(this.joinEndPoint(api, url), { params, withCredentials })
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public getNoParams<T>(api: string, url: string, withCredentials: boolean = true): Observable<T> {
        return this.http.get<T>(this.joinEndPoint(api, url), { withCredentials });
    }

    public put<T>(api: string, url: string, data: any, addToken: boolean = true,
        contentType: ContentTypeEnum = ContentTypeEnum.JSON): Observable<T> {
        return this.http.put<T>(this.joinEndPoint(api, url), data, this.getOptions(data, contentType, addToken));
    }

    public patch<T>(api: string, url: string, data: any, addToken: boolean = true): Observable<T> {
        return this.http
            .patch<T>(this.joinEndPoint(api, url), data, { withCredentials: addToken })
            .map(this.extractData)
            .catch(this.handleError.bind(this));
    }

    public getOptions(params: HttpParams, contentType: ContentTypeEnum, addToken: boolean): {} {
        let headers;
        headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json');

        if (addToken) {
            const jwt: string = this.jwtService.getToken();
            if (jwt) {
                headers.append('Authorization', 'Bearer ' + jwt);
            }
        }

        return { headers, params, withCredentials: addToken };
    }

    public getOptionsNoParams(contentType: ContentTypeEnum, addToken: boolean): {} {
        const headers = new HttpHeaders({
            Accept: 'application/json'
        });

        switch (contentType) {
            case ContentTypeEnum.JSON: {
                headers.append('Content-Type', 'application/json');
                break;
            }
            case ContentTypeEnum.FORM: {
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                break;
            }
        }

        if (addToken) {
            const jwt: string = this.jwtService.getToken();
            if (jwt) {
                headers.set('Authorization', 'Bearer ' + jwt);
            }
        }

        return [{ headers }];
    }

    private joinEndPoint(api: string, url: string): string {
        return Location.joinWithSlash(api, url);
    }

    private extractData(res: any): any {
        const body = res;
        return body || {};
    }

    private handleError(error: any) {
        if (error.status === 401) {
            throw error;
        } else {
            throw error;
        }
    }
}

export enum ContentTypeEnum {
    JSON = 1,
    FORM = 2
}
