import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioService } from '../_services/usuario.service';
import { MenuService } from '../_services/menu.service';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContratoService } from '../_services/contrato.service';
import { EdpModule, EdpPaginatorModule } from 'ngx-edp';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule
} from '@angular/material';
import { PequenasEGrandesEmpresasComponent } from './components/pequenas-e-grandes-empresas/pequenas-e-grandes-empresas.component';
import { VantagensDaEnergiaSolarComponent } from './components/vantagens-da-energia-solar/vantagens-da-energia-solar.component';
import { InclusoNoServicoComponent } from './components/incluso-no-servico/incluso-no-servico.component';
import { PorqueEscolherEDPSmartComponent } from './components/porque-escolher-edpsmart/porque-escolher-edpsmart.component';
import { QuerSaberComoEconomizarComponent } from './components/quer-saber-como-economizar/quer-saber-como-economizar.component';
import { CheckoutService } from '../_services/checkout.service';
import { OrcamentoFormResidencialComponent } from './components/orcamento-form-residencial/orcamento-form-residencial.component';
import { UploadOrcamentoComponent } from './components/upload-orcamento/upload-orcamento.component';
import { MsgSucessoOrcamentoComponent } from './components/msg-sucesso-orcamento/msg-sucesso-orcamento.component';
import { CupomDescontoComponent } from './components/cupom-desconto/cupom-desconto.component';
import { UnidadeConsumidoraComponent } from './components/unidade-consumidora/unidade-consumidora.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgxMaskModule } from 'ngx-mask';
import { OrcamentoFormEmpresarialComponent } from './components/orcamento-form-empresarial/orcamento-form-empresarial.component';
import { RouterModule } from '@angular/router';
import { TermosCondicoesComponent } from './components/termos-condicoes/termos-condicoes.component';
import { FooterComponent } from './components/footer/footer.components';

@NgModule({
    declarations: [
        PequenasEGrandesEmpresasComponent,
        VantagensDaEnergiaSolarComponent,
        InclusoNoServicoComponent,
        PorqueEscolherEDPSmartComponent,
        QuerSaberComoEconomizarComponent,
        UploadOrcamentoComponent,
        MsgSucessoOrcamentoComponent,
        CupomDescontoComponent,
        UnidadeConsumidoraComponent,
        OrcamentoFormResidencialComponent,
        OrcamentoFormEmpresarialComponent,
        TermosCondicoesComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CollapseModule.forRoot(),
        CarouselModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MatFormFieldModule,
        EdpModule,
        EdpPaginatorModule,
        RecaptchaModule.forRoot(),
        RecaptchaFormsModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [
        PequenasEGrandesEmpresasComponent,
        VantagensDaEnergiaSolarComponent,
        InclusoNoServicoComponent,
        PorqueEscolherEDPSmartComponent,
        QuerSaberComoEconomizarComponent,
        UploadOrcamentoComponent,
        MsgSucessoOrcamentoComponent,
        CupomDescontoComponent,
        UnidadeConsumidoraComponent,
        OrcamentoFormResidencialComponent,
        OrcamentoFormEmpresarialComponent,
        TermosCondicoesComponent,
        FooterComponent
    ],
    providers: [
        UsuarioService,
        MenuService,
        ContratoService,
        CheckoutService
    ]
})
export class SharedModule { }
