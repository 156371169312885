import { Component, OnInit, AfterViewInit } from '@angular/core';
import settings from 'src/settings';
import { SaveRouteOrigin } from 'src/app/utils/save-route/save-route';

@Component({
  selector: 'app-grandes-empresas',
  templateUrl: './grandes-empresas.component.html',
  styleUrls: ['./grandes-empresas.component.scss']
})
export class GrandesEmpresasComponent implements AfterViewInit {

  ngAfterViewInit() {
    SaveRouteOrigin(window.location.href);
  }

  public urlSimulador = settings.URL_SOLAR_SIMULADOR_EMPRESAS;
  public urlContato = settings.URL_SOLAR_GRANDES_EMPRESAS_CONTATO;
  public paginaContato = false;

  constructor() { }

  solicitarContato() {
    this.paginaContato = true;
  }

  oQueESolar() {
    location.href = this.urlSimulador;
}
  oContato() {
    location.href = this.urlContato;
}
}
