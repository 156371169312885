export class StringUtil {
    public static isNullOrUndefined(valor: any): boolean {
        switch (valor) {
            case '':
            case null:
            case undefined:
            case 'undefined':
                return true;
            default:
                return false;
        }
    }
}