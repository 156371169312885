/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-artigo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ngx-edp/ngx-edp.ngfactory";
import * as i3 from "ngx-edp";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "./faq-artigo.component";
import * as i7 from "../../../_services/usuario.service";
import * as i8 from "angular-oauth2-oidc";
var styles_FaqArtigoComponent = [i0.styles];
var RenderType_FaqArtigoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqArtigoComponent, data: {} });
export { RenderType_FaqArtigoComponent as RenderType_FaqArtigoComponent };
export function View_FaqArtigoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "edp-faq-artigo-view", [["class", "fix-margin"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵct_0, i2.RenderType_ɵct)), i1.ɵdid(1, 114688, null, 0, i3.ɵct, [i4.Title, i3.EdpFaqService, i5.ActivatedRoute, i3.ɵck], { usuario: [0, "usuario"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usuarioAtual$; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FaqArtigoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq-artigo-solar", [], null, null, null, View_FaqArtigoComponent_0, RenderType_FaqArtigoComponent)), i1.ɵdid(1, 114688, null, 0, i6.FaqArtigoComponent, [i7.UsuarioService, i8.OAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqArtigoComponentNgFactory = i1.ɵccf("app-faq-artigo-solar", i6.FaqArtigoComponent, View_FaqArtigoComponent_Host_0, {}, {}, []);
export { FaqArtigoComponentNgFactory as FaqArtigoComponentNgFactory };
