import { ServiceHelpersService } from './service-helper.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import settings from 'src/settings';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ContratoService {

    constructor(private serviceHelper: ServiceHelpersService) { }

    public obterPorCpfCnpj = (cpfCnpj: string): Observable<any> =>
        this.fetchContratoByUrlSegment(`/api/Contrato/obterPorCpfCnpj?cpfCnpj=${cpfCnpj}`, null)

    private fetchContratoByUrlSegment(url: string, params: HttpParams): Observable<any> {
        return this.serviceHelper.get<any>(settings.PROVEDOR_DADOS_API, url, params);
    }
}
