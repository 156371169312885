import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { GrandesEmpresasService } from "src/app/_services/grandes-empresas.service";
import { ContatoGrandesEmpresasB2BModel } from "../model/contatoGrandesEmpresasB2B.model";
import { SaveRouteOrigin } from "src/app/utils/save-route/save-route";
import { UploadOrcamentoComponent } from "src/app/shared/components/upload-orcamento/upload-orcamento.component";
import { AnexoModel } from "src/app/shared/components/upload-orcamento/model/AnexoModel";
import { IUnidadeNegocio } from "ngx-edp";
import { UsuarioService } from "src/app/_services/usuario.service";
import { InformacoesSimulacao } from "src/app/modules/components/simulador/simulador-b2b/model/informacoes-simulacao-model";
import {
  ClientePotencialService,
  CodigoAreaNegocio,
  CodigoAResponsabilidadeComercial,
  Status,
} from "src/app/_services/cliente-potencial.service";
import settings from "src/settings";
import { SimuladorService } from "src/app/_services/simulador.service";
import { PartnerIBGEData } from "src/app/utils/igbeUF";

@Component({
  selector: "app-contato",
  templateUrl: "./grandes-empresas-contato.component.html",
  styleUrls: ["./grandes-empresas-contato.component.scss"],
})
export class GrandesEmpresasContatoComponent implements OnInit, AfterViewInit {
  public grandesEmpresasContatoForm: FormGroup;
  public arquivos: Array<AnexoModel>;
  public contatoGrandesEmpresasB2BModel: ContatoGrandesEmpresasB2BModel;
  public telaMensagemEnviada = false;
  public newsletter = 0;
  public uploadObrigatorio = false;
  public headerInfo =
    "Com a sua fatura, conseguimos oferecer a solução ideal para o seu perfil.";
  municipios: string[] = [];
  estados: string[] = [];

  comercialAtivo: boolean = false;
  industriaAtivo: boolean = false;
  reaisAtivo: boolean = false;
  kwhAtivo: boolean = false;
  setores: Array<any> = [];
  tipoSimulacao: string;
  desabilitarEnvio: boolean = false;

  @ViewChild("uploadOrcamentoComponent", { static: false })
  private uploadOrcamentoComponent: UploadOrcamentoComponent;

  constructor(
    private formBuilder: FormBuilder,
    private grandesEmpresasService: GrandesEmpresasService,
    private usuarioService: UsuarioService,
    private clientePotencialService: ClientePotencialService,
    private simuladorService: SimuladorService
  ) {}

  ngOnInit() {
    this.criarFormulario();
    this.usuarioService.usuarioAtual.subscribe((x) => {
      if (x) {
        this.grandesEmpresasContatoForm.controls.aceitoReceber.setValue(
          x.aceitarInformacoes
        );
      }
    });

    this.grandesEmpresasContatoForm.controls["municipio"].disable();
  }

  ngAfterViewInit() {
    SaveRouteOrigin(window.location.href);
    this.simuladorService
      .obterTodosOsEstadosDisponiveis()
      .subscribe((resultado) => {
        this.estados = resultado.dado;
      });

      this.setores = [
        {
          id: 1,
          name: 'Comercial',
        },
        {
          id: 2,
          name: 'Industrial',
        },
      ];
  }

  criarFormulario() {
    this.grandesEmpresasContatoForm = this.formBuilder.group({
      empresa: ["", Validators.required],
      cnpj: ["", Validators.required],
      nome: ["", Validators.required],
      sobrenome: ["", Validators.required],
      email: ["", Validators.required],
      telefone: [""],
      celular: ["", Validators.required],
      dadosFatura: this.formBuilder.group({
        upload: [""],
      }),
      newsletter: [""],
      setor: [1, Validators.required],
      valorContaMensal: ["", Validators.required],
      reaisKwh: [false, Validators.required],
      comercialIndustrial: [false, Validators.required],
      mensagem: [""],
      aceitoReceber: [""],
      cep: [""],
      estado: ["", Validators.required],
      municipio: ["", Validators.required],
      ibge: [""],
    });
  }

  enviarContato() {
    this.verificaCamposForm(this.grandesEmpresasContatoForm);
    if (this.grandesEmpresasContatoForm.valid) {
      this.cadastrarClientePotencial();
    }
  }

  limparCep() {
    this.grandesEmpresasContatoForm.controls["cep"].setValue("");
    this.grandesEmpresasContatoForm.controls["cep"].clearValidators();
  }

  buscaEndereco(cep: string) {
    if (cep != "" && cep.length == 9) {
      this.simuladorService
        .obterCidadeEstadoPorCep(cep, "json")
        .subscribe((x) => {
          if (x.erro == true) {
            this.limparEstadoCidade();
            this.grandesEmpresasContatoForm.controls["cep"].setErrors({
              incorrect: true,
            });
          } else {
            this.grandesEmpresasContatoForm.controls["estado"].setValue(x.uf);
            this.simuladorService
              .obterTodasCidadesDisponiveisPorEstado(x.uf)
              .subscribe((resultado) => {
                this.municipios = resultado.dado;
                this.grandesEmpresasContatoForm.controls["municipio"].setValue(
                  x.localidade
                );
                this.grandesEmpresasContatoForm.controls["ibge"].setValue(
                  x.ibge
                );

                this.grandesEmpresasContatoForm.controls["municipio"].enable();

                this.removerValidacao("municipio");
              });
          }
        });
    } else if (cep != "" && cep.length != 9) {
      this.grandesEmpresasContatoForm.controls["cep"].setErrors({
        incorrect: true,
      });
    } else {
      this.limparEstadoCidade();
    }
  }

  limparEstadoCidade() {
    this.grandesEmpresasContatoForm.controls["municipio"].setValue("");
    this.grandesEmpresasContatoForm.controls["estado"].setValue("");
    this.grandesEmpresasContatoForm.controls["ibge"].setValue("");

    this.removerValidacao("estado");
    this.removerValidacao("municipio");
  }

  removerValidacao(control: string) {
    this.grandesEmpresasContatoForm.get(control).clearValidators();
    this.grandesEmpresasContatoForm.get(control).updateValueAndValidity();
  }

  obterCidadesDisponiveis(uf: string) {
    if (uf.toUpperCase) {
      this.simuladorService
        .obterTodasCidadesDisponiveisPorEstado(uf)
        .subscribe((resultado) => {
          this.municipios = resultado.dado;
          this.grandesEmpresasContatoForm.controls["municipio"].setValue("");
          this.grandesEmpresasContatoForm.controls["municipio"].enable();
          this.removerValidacao("municipio");
          this.limparCep();
        });
    } else {
      this.grandesEmpresasContatoForm.controls["municipio"].setValue("");
      this.grandesEmpresasContatoForm.controls["municipio"].disable();
      this.removerValidacao("municipio");
    }
  }

  obterUnidadeMedida(): number {
    if (this.reaisAtivo) return settings.UNIDADE_MEDIDA_CONSUMO_TOTAL_ANUAL_R$;

    return settings.UNIDADE_MEDIDA_CONSUMO_TOTAL_ANUAL_KWH;
  }

  cadastrarClientePotencial() {
    const codigoIbgeUf = PartnerIBGEData.getIbgeUf(
      this.grandesEmpresasContatoForm.controls.estado.value
    );

    const clientePotencial = {
      Nome_Empresa: this.grandesEmpresasContatoForm.controls.empresa.value,
      Razao_Social: this.grandesEmpresasContatoForm.controls.empresa.value,
      Cnpj: this.grandesEmpresasContatoForm.controls.cnpj.value,
      Nome_Contato: this.grandesEmpresasContatoForm.controls.nome.value,
      Sobrenome_Contato:
        this.grandesEmpresasContatoForm.controls.sobrenome.value,
      Telefone: this.grandesEmpresasContatoForm.controls.telefone.value,
      Email: this.grandesEmpresasContatoForm.controls.email.value,
      Celular: this.grandesEmpresasContatoForm.controls.celular.value,
      Codigo_origem: settings.CODIGO_ORIGEM_CLIENTE_POTENCIAL,
      Codigo_area_negocio: CodigoAreaNegocio.Solar,
      Aceita_receber_ofertas_edp:
        this.grandesEmpresasContatoForm.controls.newsletter.value,
      Solucoes_Interesse: "",
      Linkedin_Contato: "",
      Linkedin_Conta: "",
      Status: Status.Novo,
      Observacoes: this.grandesEmpresasContatoForm.controls.mensagem.value,
      Gasto_anual_total_energia:
        this.grandesEmpresasContatoForm.controls.valorContaMensal.value * 12,
      Unidade_medida_consumo_total_anual: this.obterUnidadeMedida(),
      Consumo_total_anual:
        this.grandesEmpresasContatoForm.controls.valorContaMensal.value * 12,
      Cep: this.grandesEmpresasContatoForm.controls.cep.value,
      Classe_tarifaria_codigo:
        this.grandesEmpresasContatoForm.controls.setor.value,
      Codigo_ibge_cidade: this.grandesEmpresasContatoForm.controls.ibge.value,
      Codigo_ibge_uf_estado: codigoIbgeUf.toString(),
    };

    this.desabilitarEnvio = true;
    this.clientePotencialService.gerarLead(clientePotencial).subscribe(() => {
      this.telaMensagemEnviada = true;
    });
  }

  mapearContato(uploads) {
    this.contatoGrandesEmpresasB2BModel = new ContatoGrandesEmpresasB2BModel();
    this.contatoGrandesEmpresasB2BModel.nome =
      this.grandesEmpresasContatoForm.controls.nome.value;
    this.contatoGrandesEmpresasB2BModel.email =
      this.grandesEmpresasContatoForm.controls.email.value;
    this.contatoGrandesEmpresasB2BModel.telefone =
      this.grandesEmpresasContatoForm.controls.telefone.value;
    this.contatoGrandesEmpresasB2BModel.nomeEmpresa =
      this.grandesEmpresasContatoForm.controls.nomeEmpresa.value;
    this.contatoGrandesEmpresasB2BModel.mensagem =
      this.grandesEmpresasContatoForm.controls.mensagem.value;

    this.contatoGrandesEmpresasB2BModel.newsLetter =
      this.grandesEmpresasContatoForm.controls.newsletter.value == true;
    this.contatoGrandesEmpresasB2BModel.arquivos =
      uploads == null ? new Array<AnexoModel>() : uploads;
  }

  verificaCamposForm(form) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  validaAccordion(formGroupName: string, painelId: string) {
    const isFormValid =
      this.grandesEmpresasContatoForm.controls[formGroupName].valid;
    if (!isFormValid) {
      document
        .getElementById(painelId)
        .parentElement.parentElement.parentElement.parentElement.parentElement.setAttribute(
          "class",
          "accordion-error"
        );
    } else {
      document
        .getElementById(painelId)
        .parentElement.parentElement.parentElement.parentElement.parentElement.setAttribute(
          "class",
          "accordion"
        );
    }
  }

  onClickNewsletter(isChecked) {
    if (isChecked) {
      this.newsletter = 1;
    } else if (!isChecked) {
      this.newsletter = 0;
    }
  }

  validaFormGroupAccordion(formGroupName) {
    if (
      (this.grandesEmpresasContatoForm.controls[formGroupName] as FormGroup)
        .status === "INVALID"
    ) {
      Object.keys(
        (this.grandesEmpresasContatoForm.controls[formGroupName] as FormGroup)
          .controls
      ).forEach((field) => {
        const control = (
          this.grandesEmpresasContatoForm.controls[formGroupName] as FormGroup
        ).get(field);
        control.markAsTouched({ onlySelf: true });

        const elmnt = document.getElementById("accordion_" + formGroupName);
        elmnt.setAttribute("class", "accordion-error");
        this.scroll(elmnt, "end");
      });
    }
  }

  scroll(elementId, posicao: string) {
    elementId.scrollIntoView({
      block: posicao,
      behavior: "smooth",
      inline: "center",
    });
  }

  listaArquivos(arquivos: Array<any>) {
    this.arquivos = arquivos;
  }

  alteraTipoSimulacaoSetor(value: boolean, form: FormGroup) {
    form.controls["comercialIndustrial"].setValue(value);

    if (value) {
      this.grandesEmpresasContatoForm.controls["setor"].setValue(2);
      this.comercialAtivo = false;
      this.industriaAtivo = true;
    } else {
      this.grandesEmpresasContatoForm.controls["setor"].setValue(1);
      this.comercialAtivo = true;
      this.industriaAtivo = false;
    }
  }

  alteraTipoSimulacao(value: boolean, form: FormGroup) {
    form.controls["reaisKwh"].setValue(value);

    if (value) {
      this.reaisAtivo = false;
      this.kwhAtivo = true;
    } else {
      this.reaisAtivo = true;
      this.kwhAtivo = false;
    }
  }
}
