<div class="container msg-container">
    <div class="row">
        <div class="col-md-12 msg-titulo-container">
            <label>
                Mensagem enviada com sucesso
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 msg-body-container">
            <label>
                Obrigado pelo envio!
                <br />
                Sua mensagem foi enviada para a nossa equipe comercial,
                <br />
                em breve entraremos em contato.
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 msg-btn-container">
            <div style="padding-top: 20px;" class="form-group cta align-btn">
                <a href="{{ irHome }}"><edp-button>IR PARA
                        HOME</edp-button></a>
            </div>
        </div>
    </div>
</div>