import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SaveRouteOrigin } from 'src/app/utils/save-route/save-route';
import { PoliticaDePrivacidadeServico } from 'src/app/_services/politica-de-privacidade.service';

@Component({
  selector: 'app-politica-de-privacidade',
  templateUrl: './politica-de-privacidade.component.html',
  styleUrls: ['./politica-de-privacidade.component.scss']
})
export class PoliticaDePrivacidadeComponent implements OnInit {
  constructor(private titulo: Title, private politicaDePrivacidade: PoliticaDePrivacidadeServico) {
    titulo.setTitle('EDP Smart - Energia Solar - Nossa politica de privacidade');
  }

  ngOnInit() {
    SaveRouteOrigin(window.location.href);
  }
}
