<div class="container msg-container">
  <div class="row">
    <div class="col-md-12 msg-titulo-container">
      <label>
        Agradecemos seu interesse em Energia Solar EDP.
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 msg-body-container">
      <label>
        Em breve a equipe da EDP Smart entrará em contato pelo canal escolhido <span class="email-span" id="email-span"> {{email}}
        </span>
      </label>
    </div>
  </div>
  <div class="row desk">
    <div class="col-md-12 msg-btn-container">
      <span id="ir-home-desk" class="ir-home">
        <a href="{{ irHomeUrl }}">IR PARA HOME <i class="material-icons md-18 contato-icone"
            aria-expanded="false">arrow_forward</i></a>
      </span>
      <a href="{{ outrosServicosUrl }}"><button class="btn btn-large btn-home" id="btn-home-desk" type="submit">Ver mais
          serviços</button></a>
    </div>
  </div>
  <div class="row mobile">
    <div class="col-md-12 msg-btn-container">
      <a href="{{ outrosServicosUrl }}"><button class="btn btn-large btn-home" id="btn-home-mobile" type="submit">Ver mais
          serviços</button></a>
    </div>
    <div class="col-md-12 ir-home-container">
      <span class="ir-home" id="ir-home-mobile">
        <a href="{{ irHomeUrl }}">IR PARA HOME <i class="material-icons md-18 contato-icone"
            aria-expanded="false">arrow_forward</i></a>
      </span>
    </div>
  </div>
</div>
