import { getEnvConfig } from "ngx-edp";

export default Object.assign({}, getEnvConfig("prod"), {
  CLIENT_ID: "edpsolarprd",
  RE_CAPTCHA_SITE_KEY: "6Lf85K8ZAAAAAD3seIbN8G8BB5p6TJODsTGiIRAK",
  GOOGLE_TAG_MANAGER_KEY: "GTM-TSDVFS7",
  PROVEDOR_DADOS_API: "https://app-solar-back-prd.azurewebsites.net",
  // PROVEDOR_DADOS_API: "https://api.edp.com.br/apim/edp-smart-b2b-api/qa",
  URL_SOLAR_SIMULADOR_EMPRESAS:
    "https://solar.edpsmart.com.br/empresas/pequenas-empresas/simulador",
  URL_SOLAR_GRANDES_EMPRESAS_CONTATO: "/empresas/grandes-empresas/contato",
  URL_TERMOSCONDICOESB2B: "/empresas/termos-e-condicoes",
  URL_TERMOSCONDICOESB2C: "/residencias/termos-e-condicoes",
  POLITICA_DE_PRIVACIDADE_B2B: "/empresas/politica-de-privacidade",
  POLITICA_DE_PRIVACIDADE_B2C: "/residencias/politica-de-privacidade",
  URL_CLIENTE_POTENCIAL: "https://api.edp.com.br/apim/edp-smart-b2b-api/prd",
  USER_ID_CLIENTE_POTENCIAL: "SiteB2B",
  APIM_KEY_CLIENTE_POTENCIAL: "377caf88d3974396bd76e1ff295280d5",
  CODIGO_ORIGEM_CLIENTE_POTENCIAL: 448780001,
  UNIDADE_MEDIDA_CONSUMO_TOTAL_ANUAL_R$: 3,
  UNIDADE_MEDIDA_CONSUMO_TOTAL_ANUAL_KWH: 1,
});
