import { AnexoModel } from 'src/app/shared/components/upload-orcamento/model/AnexoModel';

export class ContatoGrandesEmpresasB2BModel {
    public nome: string;
    public email: string;
    public telefone: string;
    public nomeEmpresa: string;
    public mensagem: string;
    public arquivos: Array<AnexoModel>;
    public newsLetter: boolean;
}
