<div *ngIf="!telaMensagemEnviada; else mensagemEnviada">
  <app-header-grandes-empresas></app-header-grandes-empresas>
  <div class="container contato-content">
    <div class="row">
      <div class="painel-esquerda col-md-6">
        <div class="contato-titulo col-md-12">Comece a economizar agora</div>

        <div class="contato-body col-md-12">
          Entre em contato com a EDP Smart através do endereço de e-mail abaixo
          ou solicite o contato pelo formulário ao lado e receba uma proposta
          personalizada, sem custo e sem compromisso.
        </div>

        <div class="fone-email-container">
          <div class="row contato-email-container col-md-12">
            <i
              class="material-icons md-18 contato-email-icone"
              aria-expanded="false"
              >mail_outline</i
            >
            <span class="contato-email-endereco"
              >contato.solar@edpbr.com.br</span
            >
          </div>
        </div>
      </div>

      <div class="painel-direita col-md-6">
        <form
          [formGroup]="grandesEmpresasContatoForm"
          #form="ngForm"
          enctype="multipart/form-data"
          method="POST"
          (ngSubmit)="enviarContato(form.value)"
          class="ng-untouched ng-pristine ng-invalid"
        >
          <div class="fields">
            <div class="contato-form-titulo">Solicite um contato</div>

            <div class="dados">
              <div class="row contato-form-input-container">
                <div class="col-md-6 col-sm-12 contato-form-item">
                  <label for="empresa" class="contato-form-label"
                    >Empresa<span class="txt-obrigatorio">*</span></label
                  >
                  <input
                    type="text"
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="empresa"
                    maxlength="35"
                    placeholder="Empresa LTDA"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['empresa']
                          .invalid &&
                        (grandesEmpresasContatoForm.controls['empresa'].dirty ||
                          grandesEmpresasContatoForm.controls['empresa']
                            .touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['empresa'].valid &&
                        (grandesEmpresasContatoForm.controls['empresa'].dirty ||
                          grandesEmpresasContatoForm.controls['empresa']
                            .touched)
                    }"
                  />
                  <div
                    *ngIf="
                      grandesEmpresasContatoForm.controls['empresa'].touched
                    "
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['empresa'].errors &&
                        grandesEmpresasContatoForm.controls['empresa'].errors
                          .required
                      "
                      class="alert alert-color"
                    >
                      Empresa é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['empresa']
                          .invalid &&
                        grandesEmpresasContatoForm.controls['empresa'].errors
                          .required == undefined
                      "
                      class="alert alert-color"
                    >
                      Empresa é inválido.
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12 contato-form-item">
                  <label for="cnpj" class="contato-form-label"
                    >CNPJ<span class="txt-obrigatorio">*</span></label
                  >
                  <input
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="cnpj"
                    maxlength="18"
                    mask="00.000.000/0000-00"
                    placeholder="00.000.000/0000-00"
                    type="cnpj"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['cnpj'].invalid &&
                        (grandesEmpresasContatoForm.controls['cnpj'].dirty ||
                          grandesEmpresasContatoForm.controls['cnpj'].touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['cnpj'].valid &&
                        (grandesEmpresasContatoForm.controls['cnpj'].dirty ||
                          grandesEmpresasContatoForm.controls['cnpj'].touched)
                    }"
                  />
                  <div
                    *ngIf="grandesEmpresasContatoForm.controls['cnpj'].touched"
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['cnpj'].errors &&
                        grandesEmpresasContatoForm.controls['cnpj'].errors
                          .required
                      "
                      class="alert alert-color"
                    >
                      CNPJ é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['cnpj'].invalid &&
                        grandesEmpresasContatoForm.controls['cnpj'].errors
                          .required == undefined
                      "
                      class="alert alert-color"
                    >
                      CNPJ é inválido.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row contato-form-input-container">
                <div class="col-md-6 col-sm-12 contato-form-item">
                  <label for="nome" class="contato-form-label"
                    >Nome<span class="txt-obrigatorio">*</span></label
                  >
                  <input
                    type="text"
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="nome"
                    maxlength="33"
                    pattern="Â?[a-zA-ZzâáàâãéèêíïüóôõöúçñÂÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.'][a-zA-ZzâáàâãéèêíïóüôõöúçñÂÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ.' \s]+"
                    placeholder="Nome"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['nome'].invalid &&
                        (grandesEmpresasContatoForm.controls['nome'].dirty ||
                          grandesEmpresasContatoForm.controls['nome'].touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['nome'].valid &&
                        (grandesEmpresasContatoForm.controls['nome'].dirty ||
                          grandesEmpresasContatoForm.controls['nome'].touched)
                    }"
                  />
                  <div
                    *ngIf="grandesEmpresasContatoForm.controls['nome'].touched"
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['nome'].errors &&
                        grandesEmpresasContatoForm.controls['nome'].errors
                          .required
                      "
                      class="alert alert-color"
                    >
                      Nome é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['nome'].invalid &&
                        grandesEmpresasContatoForm.controls['nome'].errors
                          .required == undefined
                      "
                      class="alert alert-color"
                    >
                      Nome é inválido.
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12 contato-form-item">
                  <label for="sobrenome" class="contato-form-label"
                    >Sobrenome<span class="txt-obrigatorio">*</span></label
                  >
                  <input
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="sobrenome"
                    maxlength="33"
                    placeholder="Sobrenome"
                    type="email"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['sobrenome']
                          .invalid &&
                        (grandesEmpresasContatoForm.controls['sobrenome']
                          .dirty ||
                          grandesEmpresasContatoForm.controls['sobrenome']
                            .touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['sobrenome']
                          .valid &&
                        (grandesEmpresasContatoForm.controls['sobrenome']
                          .dirty ||
                          grandesEmpresasContatoForm.controls['sobrenome']
                            .touched)
                    }"
                  />
                  <div
                    *ngIf="
                      grandesEmpresasContatoForm.controls['sobrenome'].touched
                    "
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['sobrenome']
                          .errors &&
                        grandesEmpresasContatoForm.controls['sobrenome'].errors
                          .required
                      "
                      class="alert alert-color"
                    >
                      Sobrenome é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['sobrenome']
                          .invalid &&
                        grandesEmpresasContatoForm.controls['sobrenome'].errors
                          .required == undefined
                      "
                      class="alert alert-color"
                    >
                      Sobrenome é inválido.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row contato-form-input-container">
                <div class="col-md-12 col-sm-12 contato-form-item">
                  <label for="email" class="contato-form-label"
                    >E-mail<span class="txt-obrigatorio">*</span></label
                  >
                  <input
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="email"
                    maxlength="100"
                    pattern="^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$"
                    placeholder="email@email.com"
                    type="email"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['email'].invalid &&
                        (grandesEmpresasContatoForm.controls['email'].dirty ||
                          grandesEmpresasContatoForm.controls['email'].touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['email'].valid &&
                        (grandesEmpresasContatoForm.controls['email'].dirty ||
                          grandesEmpresasContatoForm.controls['email'].touched)
                    }"
                  />
                  <div
                    *ngIf="grandesEmpresasContatoForm.controls['email'].touched"
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['email'].errors &&
                        grandesEmpresasContatoForm.controls['email'].errors
                          .required
                      "
                      class="alert alert-color"
                    >
                      E-mail é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['email'].invalid &&
                        grandesEmpresasContatoForm.controls['email'].errors
                          .required == undefined
                      "
                      class="alert alert-color"
                    >
                      E-mail é inválido.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row contato-form-input-container">
                <div class="col-md-6 contato-form-item">
                  <label for="celular" class="contato-form-label"
                    >Celular<span class="txt-obrigatorio">*</span></label
                  >
                  <input
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="celular"
                    mask="(00) 00000-0000"
                    placeholder="(00) 00000-0000"
                    type="tel"
                    id="celular"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['celular']
                          .invalid &&
                        (grandesEmpresasContatoForm.controls['celular'].dirty ||
                          grandesEmpresasContatoForm.controls['celular']
                            .touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['celular'].valid &&
                        (grandesEmpresasContatoForm.controls['celular'].dirty ||
                          grandesEmpresasContatoForm.controls['celular']
                            .touched)
                    }"
                  />
                  <div
                    *ngIf="
                      grandesEmpresasContatoForm.controls['celular'].touched
                    "
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['celular'].errors &&
                        grandesEmpresasContatoForm.controls['celular'].errors
                          .required
                      "
                      class="alert alert-color"
                    >
                      Celular é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['celular']
                          .invalid &&
                        grandesEmpresasContatoForm.controls['celular'].errors
                          .required == undefined
                      "
                      class="alert alert-color"
                    >
                      Celular é inválido.
                    </div>
                  </div>
                </div>

                <div class="col-md-6 contato-form-item">
                  <label for="telefone" class="contato-form-label"
                    >Telefone</label
                  >
                  <input
                    type="text"
                    class="
                      contato-input
                      form-control
                      ng-untouched ng-pristine ng-valid
                    "
                    formControlName="telefone"
                    mask="(00) 0000-0000"
                    placeholder="(00) 00000-0000"
                    [ngClass]="{
                      'is-invalid':
                        grandesEmpresasContatoForm.controls['telefone']
                          .invalid &&
                        (grandesEmpresasContatoForm.controls['telefone']
                          .dirty ||
                          grandesEmpresasContatoForm.controls['telefone']
                            .touched),
                      'is-valid':
                        grandesEmpresasContatoForm.controls['telefone'].valid &&
                        (grandesEmpresasContatoForm.controls['telefone']
                          .dirty ||
                          grandesEmpresasContatoForm.controls['telefone']
                            .touched)
                    }"
                  />
                </div>
              </div>
              <!-- <div
                class="accordion"
                id="accordion_dadosFatura"
                formGroupName="dadosFatura"
              >
                <div class="row">
                  <div class="col">
                    <label class="accordion-title">
                      <span class="accordion-title-span">
                        Última fatura de energia
                      </span>
                    </label>
                    <app-upload-orcamento
                      #uploadOrcamentoComponent
                      (listaArquivos)="listaArquivos($event)"
                      [headerInfo]="headerInfo"
                      [mensagemUploadObrigatorio]="uploadObrigatorio"
                      [uploadObrigatorio]="uploadObrigatorio"
                      [visible]="true"
                      [exibirUnidadeConsumidora]="false"
                    >
                    </app-upload-orcamento>
                  </div>
                </div>
              </div> -->

              <div class="row contato-form-input-container">
                <div class="col-md-3 mb-3 contato-form-item">
                  <label for="cep" class="contato-form-label">CEP</label>
                  <input
                    #cep
                    class="form-control ng-untouched ng-pristine"
                    formControlName="cep"
                    [ngClass]="
                      !grandesEmpresasContatoForm.controls['cep'].dirty ||
                      !grandesEmpresasContatoForm.controls['cep'].value
                        ? ''
                        : grandesEmpresasContatoForm.controls['cep'].invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    mask="00000-000"
                    placeholder="00000-000"
                    type="tel"
                    (input)="buscaEndereco(cep.value)"
                    maxlength="9"
                  />
                  <input type="text" formControlName="ibge" hidden="true" />
                  <div
                    *ngIf="
                      grandesEmpresasContatoForm.controls['cep'].invalid &&
                      (grandesEmpresasContatoForm.controls['cep'].dirty ||
                        grandesEmpresasContatoForm.controls['cep'].touched)
                    "
                  >
                    <div
                      *ngIf="
                        !grandesEmpresasContatoForm.controls['cep'].errors
                          .valid &&
                        !grandesEmpresasContatoForm.controls['cep'].errors
                          .required
                      "
                      class="alert"
                    >
                      CEP não encontrado, favor selecionar município e estado.
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mb-3 contato-form-item">
                  <label for="estado" class="contato-form-label"
                    >Estado<span> *</span></label
                  >
                  <ng-select
                    #select_estado
                    [items]="estados"
                    [searchable]="false"
                    [ngClass]="{
                      'erro-select':
                        grandesEmpresasContatoForm.controls['estado'].invalid &&
                        (grandesEmpresasContatoForm.controls['estado'].dirty ||
                          grandesEmpresasContatoForm.controls['estado'].touched)
                    }"
                    (change)="obterCidadesDisponiveis($event)"
                    bindValue="name"
                    bindLabel="name"
                    formControlName="estado"
                  >
                    <ng-template ng-header-tmp>
                      <input
                        class="pesquisa-opcoes"
                        type="text"
                        style="width: 120px"
                        (input)="select_estado.filter($event.target.value)"
                      />
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="
                      grandesEmpresasContatoForm.controls['estado'].invalid &&
                      (grandesEmpresasContatoForm.controls['estado'].dirty ||
                        grandesEmpresasContatoForm.controls['estado'].touched)
                    "
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['estado'].errors
                          .required
                      "
                      class="alert"
                    >
                      Estado é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        !grandesEmpresasContatoForm.controls['estado'].errors
                          .valid &&
                        !grandesEmpresasContatoForm.controls['estado'].errors
                          .required
                      "
                      class="alert"
                    >
                      Estado é inválido.
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 contato-form-item">
                  <label for="municipio" class="contato-form-label"
                    >Município<span> *</span></label
                  >
                  <ng-select
                    #select_municipio
                    [items]="municipios"
                    [searchable]="false"
                    bindValue="name"
                    bindLabel="name"
                    (change)="limparCep()"
                    [ngClass]="{
                      'erro-select':
                        grandesEmpresasContatoForm.controls['municipio']
                          .invalid &&
                        (grandesEmpresasContatoForm.controls['municipio']
                          .dirty ||
                          grandesEmpresasContatoForm.controls['municipio']
                            .touched)
                    }"
                    formControlName="municipio"
                  >
                    <ng-template ng-header-tmp>
                      <input
                        class="pesquisa-opcoes"
                        type="text"
                        (input)="select_municipio.filter($event.target.value)"
                      />
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="
                      grandesEmpresasContatoForm.controls['municipio']
                        .invalid &&
                      (grandesEmpresasContatoForm.controls['municipio'].dirty ||
                        grandesEmpresasContatoForm.controls['municipio']
                          .touched)
                    "
                  >
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['municipio'].errors
                          .required
                      "
                      class="alert"
                    >
                      Município é obrigatório.
                    </div>
                    <div
                      *ngIf="
                        !grandesEmpresasContatoForm.controls['municipio'].errors
                          .valid &&
                        !grandesEmpresasContatoForm.controls['municipio'].errors
                          .required
                      "
                      class="alert"
                    >
                      Município é inválido.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row contato-form-input-container">
                <div class="col-md-6 col-sm-12 contato-form-item">
                  <label for="setor" class="contato-form-label"
                    >Setor<span class="txt-obrigatorio">*</span></label
                  >
                  <div
                    class="col-md-6 custom-control custom-switch"
                    style="display: table-cell"
                  >
                    <label
                      class="label-reais"
                      id="comercial"
                      [ngClass]="comercialAtivo ? 'ativo' : 'inativo'"
                      >Comercial</label
                    >
                    <input
                      type="checkbox"
                      value="false"
                      class="custom-control-input"
                      id="customSwitch2"
                      formControlName="comercialIndustrial"
                      [checked]="false"
                      (change)="
                        alteraTipoSimulacaoSetor(
                          $event.target.checked,
                          grandesEmpresasContatoForm
                        )
                      "
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitch2"
                    ></label>
                    <label
                      class="label-kwh"
                      id="industrial"
                      [ngClass]="industriaAtivo ? 'ativo' : 'inativo'"
                      >Industrial</label
                    >
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 contato-form-item">
                  <label for="consumo" class="contato-form-label"
                    >Consumo mensal<span class="txt-obrigatorio">*</span></label
                  >
                  <div
                    class="col-md-3 custom-control custom-switch"
                    style="display: table-cell"
                  >
                    <label
                      class="label-reais"
                      id="reais"
                      [ngClass]="reaisAtivo ? 'ativo' : 'inativo'"
                      >R$</label
                    >
                    <input
                      type="checkbox"
                      value="false"
                      class="custom-control-input"
                      id="customSwitch1"
                      formControlName="reaisKwh"
                      [checked]="false"
                      (change)="
                        alteraTipoSimulacao(
                          $event.target.checked,
                          grandesEmpresasContatoForm
                        )
                      "
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitch1"
                    ></label>
                    <label
                      class="label-kwh"
                      id="kwh"
                      [ngClass]="kwhAtivo ? 'ativo' : 'inativo'"
                      >kWh</label
                    >
                  </div>

                  <div
                    class="col-md-3"
                    style="display: table-cell; padding-right: 0px"
                  >
                    <input
                      currencyMask
                      class="form-control valor-conta-mensal-espacamento"
                      min="0.01"
                      maxlength="14"
                      type="tel"
                      formControlName="valorContaMensal"
                      [ngClass]="{
                        'is-invalid':
                          grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].invalid &&
                          (grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].dirty ||
                            grandesEmpresasContatoForm.controls[
                              'valorContaMensal'
                            ].touched),
                        'is-valid':
                          grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].valid &&
                          grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].touched &&
                          grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].value > 0.0
                      }"
                      placeholder="0,00"
                      [options]="{
                        prefix: ' ',
                        thousands: '.',
                        decimal: ',',
                        align: 'left',
                        allowNegative: 'false'
                      }"
                    />
                    <div
                      *ngIf="
                        grandesEmpresasContatoForm.controls['valorContaMensal']
                          .invalid
                      "
                    >
                      <div
                        *ngIf="
                          grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].errors.required &&
                          grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].touched
                        "
                        class="alert"
                      >
                        Valor da conta mensal é obrigatório.
                      </div>
                      <div
                        *ngIf="
                          !grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].errors.valid &&
                          !grandesEmpresasContatoForm.controls[
                            'valorContaMensal'
                          ].errors.required
                        "
                        class="alert"
                      >
                        Valor da conta mensal é inválido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="col-md-12">
                  <textarea
                    formControlName="mensagem"
                    class="form-control"
                    name="mensagem"
                    id="mensagem"
                    rows="10"
                    placeholder="Mensagem (opcional)"
                  ></textarea>
                </div>
              </div>

              <div class="row contato-form-input-container">
                <div class="col-md-12 contato-form-item">
                  <div class="termos">
                    <div class="checkContainer">
                      <div class="checkField">
                        <input
                          type="checkbox"
                          formControlName="newsletter"
                          (change)="onClickNewsletter($event.target.checked)"
                          name="group1"
                          id="aceitoReceberInformacoes"
                          data-value="cenas"
                        />
                        <label
                          class="termosSpan"
                          [ngStyle]="{ color: '#939393' }"
                          for="aceitoReceberInformacoes"
                        >
                          Aceito receber informações sobre produtos e serviços
                          da
                          <a class="termosBold">EDP Smart</a>. Para mais
                          informações clique
                          <a
                            href="https://www.edpsmart.com.br/residencias"
                            target="_blank"
                            >aqui</a
                          >.</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="
                  form-group
                  cta
                  align-btn
                  btn-enviar-msg-contato-container
                "
              >
                <edp-button [disabled]="desabilitarEnvio" class="btn-avancar"
                  >ENVIAR MENSAGEM</edp-button
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ng-template #mensagemEnviada>
  <app-mensagem-enviada></app-mensagem-enviada>
</ng-template>
